import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "@reach/router";
import { NavContext } from "context/NavContext";
import styled from "styled-components";
import {
  packageFooter,
  tsWidth,
  tsMainNavHeight,
  anim1,
} from "@ecomm/typography";
import { HasOpen, HasActive, HasNumColumns, HasSection } from "types/common";
import { Images } from "constants/Images";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import {
  ICsTouchscreenEquipmentType,
  ICsTouchscreenScreenTypeFields,
} from "types/contentful";
import { Api } from "api/Api";
import { Entry } from "contentful";
import { useManualQuery } from "graphql-hooks";
import { CT_SLUGS_MAP } from "commercetools/constants";
import useProductsByKeys from "commercetools/hooks/useProductsByKeys";
import { IS_TEST_ENV } from "commercetools/client";

const Container = styled.div``;

const BackNav = styled.div<HasOpen>`
  ${tsMainNavHeight};
  ${tsWidth};
  overflow-x: hidden;
  background: rgba(62, 64, 66, 0.3);
  position: fixed;
  bottom: ${({ open }) => (open ? "0px" : "-100%")};
  left: 0px;
  right: 0px;
  z-index: 2;
  display: ${({ open }) => (open ? "block" : "none")};
`;

const BackBtnHolder = styled.div`
  ${tsWidth};
  ${tsMainNavHeight};
  background: black;
  text-align: left;
  padding-left: 40px;
`;

const BackBtn = styled.a`
  display: block;
  text-align: center;
  color: #878b93;
  ${tsMainNavHeight};
  /* border-top: 5px solid #181a1d; */
  padding-top: 40px;
  padding-left: 50px;
  ${packageFooter};
  text-align: left;
  background-image: url(${Images.globalBtnNavBackBtn});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px;
  ${anim1};
  &:active {
    color: white;
  }
  ${ccIpadPortrait`
    padding-top: 18px;
    background-size: 20px;
  `}
`;

const FooterNav = styled.div<HasOpen & HasNumColumns>`
  ${tsWidth};
  ${tsMainNavHeight};
  overflow-x: hidden;
  background: #3e4042;
  position: fixed;
  bottom: ${({ open }) => (open ? "0px" : "-100%")};
  left: 0px;
  right: 0px;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-column-gap: 2px;
  ${ccIpadPortrait`
    grid-column-gap: 1px;
  `}
`;

const FooterNavApp = styled.div<HasOpen & HasNumColumns>`
  ${tsWidth};
  ${tsMainNavHeight};
  overflow-x: hidden;
  background: #3e4042;
  position: fixed;
  bottom: ${({ open }) => (open ? "0px" : "-100%")};
  left: 0px;
  right: 0px;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 35%;
  grid-column-gap: 2px;
  ${ccIpadPortrait`
    grid-column-gap: 1px;
  `}
`;

const FooterItem = styled.a<HasActive & HasSection>`
  ${tsMainNavHeight};
  background: ${({ active, section }) => {
    if (section === "row") {
      return active ? "#181A1D" : "#000000";
    }
    if (section === "app") {
      return active ? "#000000" : "#000000";
    } else {
      return active ? "#212326" : "#181a1d";
    }
  }};
  text-align: center;
  /* color: #888b93; */
  color: ${({ active }) => (active ? "#ffffff" : "#888b93")};
  border-top: 5px solid
    ${({ active, section }) => {
      return active
        ? section === "app"
          ? "#CDFF38"
          : "rgba(223,28,47,1)"
        : "#181a1d";
    }};
  padding-top: 40px;
  ${packageFooter};
  ${anim1};
  @media (min-width: 0px) and (max-width: 1024px) {
    padding-top: 18px;
    border-top: 2px solid
      ${({ active }) => (active ? "rgba(223,28,47,1)" : "#181a1d")};
  }
  ${ccIpadPortrait`
    // TODO migrate media query here
  `}
`;

export const Navigation = () => {
  const { open, back } = useContext(NavContext);
  const navigate = useNavigate();
  const location = useLocation();
  const locationArray = location.pathname.split("/");

  const curSection = locationArray[2];
  const intlLocation = locationArray[1];
  let catalogSlug = "dotcom";
  let catalogLocale = "EN_US";
  let catalogCurrency = "USD";
  // let apiLocale = "en-US"
  let CT_SLUGS: string[] = [];
  if (curSection === 'bike') {
    CT_SLUGS = [...CT_SLUGS_MAP[intlLocation].bike, ...CT_SLUGS_MAP[intlLocation].bikePlus];
  }
  if (curSection === "tread") {
    CT_SLUGS = CT_SLUGS_MAP[intlLocation].tread;
  }

  if (intlLocation === "ca") {
    catalogSlug = "ca_webstore";
    catalogLocale = "EN_CA";
    catalogCurrency = "CAD";
    // apiLocale = "en-CA"
  }

  const PACKAGES_BY_TYPE_VARIABLE_QUERY = `query getPackageByType($type:EquipmentType!) {
      catalog(slug: "${catalogSlug}", locale: ${catalogLocale}, currency: ${catalogCurrency}) {
        packages(equipmentType: $type) {
          id
          name
          slug
          description
          price {
            amount
          }
        }
      }
    }`;

  const [contentfulEquipmentTypes, setContentfulEquipmentTypes] = useState<
    ICsTouchscreenEquipmentType[] | undefined
  >(undefined);
  const [equipmentTypes, setEquipmentTypes] = useState<
    ICsTouchscreenEquipmentType[] | undefined
  >(undefined);
  const [menuLength, setMenuLength] = useState(0);

  const [fetchPackages] = useManualQuery(PACKAGES_BY_TYPE_VARIABLE_QUERY);

  useEffect(() => {
    const fetchData = async () => {
      Api.getScreenTypesByPath(curSection).then((response) => {
        const entry: Entry<ICsTouchscreenScreenTypeFields> = response.items[0];
        // exclude row screentype until ready to go live
        setContentfulEquipmentTypes(
          (entry &&
            entry.fields.equipmentTypes?.filter(
              (e) => e.fields.enum !== "ROW"
            )) ||
            []
        );
      });
    };
    fetchData();
  }, [curSection, intlLocation]);

  // use CommerceTools data if IS_TEST_ENV is true
  const ctData = useProductsByKeys(CT_SLUGS, intlLocation);
  useEffect(() => {
    if (menuLength === 0 && contentfulEquipmentTypes && IS_TEST_ENV) {
      if (curSection === 'tread') {
        const t = contentfulEquipmentTypes.filter((e) => e.fields.enum === "TREAD");
        setEquipmentTypes(t);
        setMenuLength(2);
        return;
      }
      if (curSection === 'row') {
        setMenuLength(2);
      } else if (curSection === 'bike') {
        setMenuLength(3);
      } else {
        setMenuLength(1);
      }
      setEquipmentTypes(contentfulEquipmentTypes);
    }
  }, [ctData, menuLength, contentfulEquipmentTypes, curSection]);

  // use Kronos data if IS_TEST_ENV is false
  useEffect(() => {
    const fetchAllPackages = async (types: ICsTouchscreenEquipmentType[]) => {
      await Promise.all(
        types.map((t) => fetchPackages({ variables: { type: t.fields.enum } }))
      ).then((response) => {
        const keepTypes: ICsTouchscreenEquipmentType[] = [];
        response.forEach((data, i) => {
          const keepType =
            data && data.data.catalog.packages.length > 0 ? true : false;

          if (keepType && types[i].fields.enum !== "TREADPLUS")
            keepTypes.push(types[i]);
        });
        setEquipmentTypes(keepTypes);
        if (curSection === "row") {
          /* comment out packages for holiday */
          // setMenuLength(keepTypes.length + 3);
          setMenuLength(2);
        } else {
          /* comment out packages for holiday */
          setMenuLength(keepTypes.length + 1);
          // setMenuLength(1);
        }
      });
    };
    if (IS_TEST_ENV) return;
    if (contentfulEquipmentTypes) fetchAllPackages(contentfulEquipmentTypes);
  }, [contentfulEquipmentTypes, curSection, fetchPackages]);

  const isActive = (category: string) => {
    const curCategory = location.pathname.slice(
      location.pathname.lastIndexOf("/") + 1
    );
    return curCategory === category;
  };

  return (
    <Container>
      {curSection === "app" ? (
        <FooterNavApp open={open} columns={4}>
          <FooterItem
            section={curSection}
            active={isActive("classes")}
            onMouseUp={() =>
              navigate &&
              navigate(`/${intlLocation}/${curSection}/main/classes`, {
                replace: false,
              })
            }
          >
            Classes
          </FooterItem>
          <FooterItem
            section={curSection}
            active={isActive("programs")}
            onMouseUp={() =>
              navigate &&
              navigate(`/${intlLocation}/${curSection}/main/programs`, {
                replace: false,
              })
            }
          >
            On The Go
          </FooterItem>
          <FooterItem
            section={curSection}
            active={isActive("collections")}
            onMouseUp={() =>
              navigate &&
              navigate(`/${intlLocation}/${curSection}/main/collections`, {
                replace: false,
              })
            }
          >
            At the Gym
          </FooterItem>
          <FooterItem
            section={curSection}
            active={isActive("memberships")}
            onMouseUp={() =>
              navigate &&
              navigate(`/${intlLocation}/${curSection}/main/memberships`, {
                replace: false,
              })
            }
          >
            App Memberships
          </FooterItem>
        </FooterNavApp>
      ) : (
        <>
          {equipmentTypes !== undefined && (
            <FooterNav open={open} columns={menuLength}>
              {/* XXX: hardcoded for row */}
              {curSection === "row" && (
                <>
                  <FooterItem
                    section={curSection}
                    active={isActive("features")}
                    onMouseUp={() =>
                      navigate &&
                      navigate(`/${intlLocation}/${curSection}/main/features`, {
                        replace: false,
                      })
                    }
                  >
                    Row key features
                  </FooterItem>
                  {/* comment out packages for holiday */}
                  {/* <FooterItem
                    section={curSection}
                    active={isActive("row")}
                    onMouseUp={() =>
                      navigate &&
                      navigate(`/${intlLocation}/${curSection}/main/row`, {
                        replace: false,
                      })
                    }
                  >
                    Row Packages
                  </FooterItem> */}
                </>
              )}
              {/* comment out packages for holiday */}
              {equipmentTypes.map((type, i) => (
                <FooterItem
                  section={curSection}
                  active={isActive(`${type.fields.enum.toLocaleLowerCase()}`)}
                  onMouseUp={() =>
                    navigate &&
                    navigate(
                      `/${intlLocation}/${curSection}/main/${type.fields.enum.toLocaleLowerCase()}`,
                      {
                        replace: false,
                      }
                    )
                  }
                  key={i}
                >
                  {type.fields.name}
                </FooterItem>
              ))}

              <FooterItem
                section={curSection}
                active={isActive("alacarte")}
                onMouseUp={() =>
                  navigate &&
                  navigate(`/${intlLocation}/${curSection}/main/alacarte`, {
                    replace: false,
                  })
                }
              >
                A la carte
              </FooterItem>
            </FooterNav>
          )}
        </>
      )}

      <BackNav open={!open}>
        <BackBtnHolder>
          <BackBtn
            onMouseUp={
              // () => navigate && navigate(`/${curSection}/main/${categories[0]}`)
              //goBack()
              () => back()
            }
          >
            Back
          </BackBtn>
        </BackBtnHolder>
      </BackNav>
    </Container>
  );
};
