// import type { ProductItemOfBundle } from '@ecomm/pg-shop-accessories-display/models';
// import type { CfuAttributes } from '@ecomm/shop-configuration';
import { formatProductId } from '../constants';

export const mapBundleProductAttrsToCfuProductAttrs = ({
  attributes,
  variants,
}: any): any =>
  attributes?.map((productAttr: any) => {
    const cfuOptions = productAttr.options.map((productOpt: any) => {
      const id =
        variants?.find((productVariant: any) =>
          productVariant.configurations.find(
            (configuration: any) => configuration.option === productOpt.slug,
          ),
        )?.legacyOptionId ?? '';

      return {
        slug: productOpt.slug,
        name: productOpt.name,
        id: formatProductId(id),
      };
    });

    return {
      name: productAttr.name,
      slug: productAttr.slug,
      options: cfuOptions,
    };
  });
