import React, { useEffect, useState } from "react";
import { RouteComponentProps, useNavigate, useLocation } from "@reach/router";
import { MainCanvas } from "components/UI/MainCanvas";
import styled from "styled-components";
import {
  tsLogo,
  tsWidth,
  tsHeight,
  startScreenTitle,
  startScreenBtn,
  anim1,
} from "@ecomm/typography";
import { Images } from "../constants/Images";
import { tsbgblack1, white } from "@ecomm/colors";
import { HasImage, HasSection } from "types/common";
// import { useSpring, animated } from "react-spring";
import { LoadSpinner } from "components/UI/LoadSpinner";
import { Api } from "api/Api";
import { EntryCollection } from "contentful";
import { ICsTouchscreenScreenTypeFields } from "types/contentful";
import { useManualQuery } from "graphql-hooks";
import useProductsByKeys from "commercetools/hooks/useProductsByKeys";
import { CT_SLUGS_MAP } from "commercetools/constants";
import { IS_TEST_ENV } from "commercetools/client";

const StartScreenCanvas = styled.div`
  ${tsWidth};
  ${tsHeight};
  /* background-color: ${tsbgblack1}; */
  background-color: #101017;
  position: relative;
  color: ${white};
  display: block;
  background-image: url(${Images.globalTopGlow});

  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
`;

const Logo = styled.div`
  /* background-image: url(${Images.globalGlowLogo}); */
  ${tsLogo};
`;

// const AppScreenSaverImg = styled.div`
//   background-image: url(${Images.appScreenSaver});
//   width: 1080px;
//   height: 1920px;
//   background-size: 1080px 1920px;
// `;

const StartScreenImg1 = styled.div<HasImage>`
  position: absolute;
  ${tsWidth};
  ${tsHeight};
  top: 0px;
  background-image: url(${({ image }) => (image ? image : null)});
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
`;

const StartScreenBott = styled.div`
  position: absolute;
  height: 540px;
  bottom: 0px;
  ${tsWidth};
  text-align: center;
`;

const StartScreenTitle = styled.div`
  ${startScreenTitle};
  min-height: 150px;
`;

// const StartScreenBtn = styled.a`
//   ${startScreenBtn};
//   background-color: #ff3347;
//   ${anim1};
// `;

const StartScreenBtnRect = styled.a`
  ${startScreenBtn};
  background-color: #ff3347;
  color: #ffffff;
  border-radius: 100px;
  ${anim1};
`;

const RowScreenSaverCopy = styled.div<HasSection>`
  font-family: "Inter", sans-serif;
  font-size: 180px;
  line-height: 0.85em;
  font-weight: 700;
  letter-spacing: 10px;
  text-transform: uppercase;
  width: auto;
  margin-top: -430px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;

  /* border: 1px solid blue; */
  ${anim1};
  div {
    display: inline-block;
    max-width: ${({ section }) => {
      if (section === "row") {
        return "46%";
      }
      if (section === "bike") {
        return "60%";
      } else {
        return "60%";
      }
    }};
    text-align: left;
  }
`;

const ScreensaverAnim = styled.div`
  position: relative;
`;

const Video = styled.video`
  position: absolute;
  object-fit: cover;
  width: 1080px;
  height: 1920px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  /* border: 1px solid red; */
`;

export const StartScreen = (props: RouteComponentProps) => {
  const intlLocation = props?.location?.pathname?.split("/")[1];

  let catalogSlug = "dotcom";
  let catalogLocale = "EN_US";
  let catalogCurrency = "USD";
  // let apiLocale = "en-US"
  if (intlLocation === "ca") {
    catalogSlug = "ca_webstore";
    catalogLocale = "EN_CA";
    catalogCurrency = "CAD";
    // apiLocale = "en-CA"
  }

  const navigate = useNavigate();
  const location = useLocation();

  const curSection = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );
  let CT_SLUGS: string[] = [];
  if (curSection === 'bike') {
    CT_SLUGS = [...CT_SLUGS_MAP[intlLocation as string].bike, ...CT_SLUGS_MAP[intlLocation as string].bikePlus];
  }
  if (curSection === "tread") {
    CT_SLUGS = CT_SLUGS_MAP[intlLocation as string].tread;
  }

  const PACKAGES_BY_TYPE_VARIABLE_QUERY = `query getPackageByType($type:EquipmentType!) {
    catalog(slug: "${catalogSlug}", locale: ${catalogLocale}, currency: ${catalogCurrency}) {
      packages(equipmentType: $type) {
        id
        name
        slug
        description
        price {
          amount
        }
      }
    }
  }`;

  const [screenData, setScreenData] = useState<EntryCollection<
    ICsTouchscreenScreenTypeFields
  > | null>(null);
  const [contentfulEquipmentTypes, setContentfulEquipmentTypes] = useState<
    string[]
  >([]);
  // const [equipmentTypes, setEquipmentTypes] = useState<string[] | undefined>(undefined);

  const [fetchPackages] = useManualQuery(PACKAGES_BY_TYPE_VARIABLE_QUERY);
  const [didLoad, setDidLoad] = useState<boolean>(false);
  const [preloadComplete, setPreloadComplete] = useState<boolean>(false);
  // const [selectedPackage, setSelectedPackage] = useState(0);
  // const [packageGraphData, setPackageGraphData] = useState<any | null>(null);
  const [screensaver, setScreensaver] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  // const [animActive, setAnimActive] = useState<boolean>(false);
  const [imgArr, setImgArr] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      Api.getScreenTypesByPath(curSection).then((response) => {
        setScreenData(response);
        const enums: string[] = [];
        if (response.items.length > 0)
          response.items[0].fields.equipmentTypes?.forEach((equipmentType) =>
            enums.push(equipmentType.fields.enum)
          );
        setContentfulEquipmentTypes(enums);
        // console.log("enums", enums);
        // remove packages from nav temporarily
        if (!enums.length) {
          if (curSection === "yoga") {
            setImgArr([Images["navYogaScreensaver1"]]);
            setSelectedCategory("alacarte");
          } else if (curSection === "strength") {
            setImgArr([Images["navStrengthScreensaver1"]]);
            setSelectedCategory("alacarte");
          } else if (curSection === "row") {
            setImgArr([Images["navRowScreensaver1"]]);
            setSelectedCategory("features");
          } else if (curSection === "app") {
            setImgArr([Images["appScreenSaver"]]);
            setSelectedCategory("classes");
          }
        }
      });
    };
    if (!screenData) fetchData();
  }, [curSection, screenData]);

  // useMemo(() => {
  //   const getPackagePrice = (slug: string) => {
  //     //@ts-ignore
  //     const p = packageGraphData[0].find((a) => {
  //       return a.slug === slug;
  //     });
  //     return p ? p.price.amount / 100 : 0;
  //   };

  //   if (
  //     contentfulEquipmentTypes &&
  //     contentfulEquipmentTypes.length &&
  //     packageGraphData &&
  //     packageGraphData.length
  //   ) {
  //     console.log("contentfulEquipmentTypes", contentfulEquipmentTypes);
  //     Api.getPackagesByEquipmentTypes(contentfulEquipmentTypes.join(",")).then(
  //       (response) => {
  //         if (response.items) {
  //           const screenSavers = response.items.reduce(
  //             (_acc: string[], _curr) => {
  //               if (
  //                 _curr.fields.screensaverUrl?.fields.file.url &&
  //                 getPackagePrice(_curr.fields.slug) > 0
  //               )
  //                 _acc.push(_curr.fields.screensaverUrl?.fields.file.url);
  //               return _acc;
  //             },
  //             []
  //           );
  //           setImgArr(screenSavers);
  //         }
  //       }
  //     );
  //   }
  // }, [contentfulEquipmentTypes, packageGraphData]);

  const ctData = useProductsByKeys(CT_SLUGS, intlLocation);
  useEffect(() => {
    if (ctData?.products?.length > 0 && contentfulEquipmentTypes.length > 0) {
      setSelectedCategory(contentfulEquipmentTypes[0].toLocaleLowerCase());
    }
  }, [contentfulEquipmentTypes, curSection, ctData]);

  useEffect(() => {
    const fetchAllPackages = async (types: string[]) => {
      // types = ["TREAD"];
      console.log("fetching packages", types);
      await Promise.all(
        types.map((t) => fetchPackages({ variables: { type: t } }))
      ).then((response) => {
        const keepTypes: string[] = [];
        const packages: any = [];
        response.forEach((data, i) => {
          console.log("data", data);
          const keepType =
            data && data?.data?.catalog.packages.length > 0 ? true : false;
          if (keepType) {
            keepTypes.push(types[i]);
            packages.push(data.data.catalog.packages);
          }
        });
        if (keepTypes.length >= 1) {
          setSelectedCategory(keepTypes[0].toLocaleLowerCase());
          // setPackageGraphData(packages);
        } else {
          setSelectedCategory("alacarte");
        }
      });
    };
    if (contentfulEquipmentTypes.length > 0 && !IS_TEST_ENV)
      fetchAllPackages(contentfulEquipmentTypes);
  }, [isLoading, contentfulEquipmentTypes, fetchPackages]);

  // const selectNextPackage = useCallback(() => {
  //   if (
  //     curSection !== "yoga" &&
  //     curSection !== "strength" &&
  //     curSection !== "row" &&
  //     curSection !== "app"
  //   ) {
  //     setAnimActive(false);
  //   }
  // }, [curSection]);

  useEffect(() => {
    if (imgArr.length && !didLoad) {
      setDidLoad(true);
    }
    // const interval = setInterval(() => {
    //   selectNextPackage();
    // }, 6000);
    // return () => clearInterval(interval);
  }, [didLoad, imgArr]);

  // const animComplete = () => {
  //   if (
  //     curSection === "yoga" ||
  //     curSection === "strength" ||
  //     curSection === "row" ||
  //     curSection === "app"
  //   ) {
  //     return;
  //   }
  //   const numPackages = imgArr.length;
  //   selectedPackage < numPackages - 1
  //     ? setSelectedPackage(selectedPackage + 1)
  //     : setSelectedPackage(0);

  //   setScreensaver(imgArr[selectedPackage]);
  //   setAnimActive(true);
  // };

  // const anim = useSpring({
  //   opacity: animActive ? 1 : 0,
  //   onRest: () => {
  //     if (!animActive && didLoad) animComplete();
  //   },
  // });

  if (didLoad && !preloadComplete) {
    let cacheInProgress = false;
    const promises: Promise<string>[] = [];
    const cacheImages = (arr: string[]) => {
      cacheInProgress = true;
      arr.forEach((src) => {
        const p = new Promise<string>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(src);
          img.onerror = () => reject();
        });
        promises.push(p);
      });
      Promise.all(promises).then(() => {
        setIsLoading(false);
        setPreloadComplete(true);
        if (curSection === "yoga" || curSection === "strength") {
          setScreensaver(imgArr[0]);
          //  setAnimActive(true);
        }
      });
    };
    if (!cacheInProgress) cacheImages(imgArr);
  }

  return (
    <MainCanvas>
      <StartScreenCanvas>
        {isLoading ? (
          <LoadSpinner>Loading...</LoadSpinner>
        ) : (
          <ScreensaverAnim>
            <StartScreenImg1 image={screensaver}></StartScreenImg1>
          </ScreensaverAnim>
        )}

        {curSection === "app" && (
          <Video
            src="https://videos.ctfassets.net/6jnflt57iyzx/nyMjKRGFBtnWwBnaZX6FM/97c1002e18cf6ae39a9147bc02d009ff/230028_APP_Instructor-Video_SIGNU-15sec_V10_1080x1920.mp4"
            poster="https://images.ctfassets.net/6jnflt57iyzx/Cr4DeKdWilId6oWtAAkKX/283672fd36fb52163d78be121070ac67/app_intro_1080x1920.jpg"
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
            playsInline
          ></Video>
        )}
        {curSection === "row" && (
          <Video
            src="https://videos.ctfassets.net/6jnflt57iyzx/5XIAKxHhqcJmImfAXVTKcE/2bc76158d68b6999c7ce09660e018b47/230055_RETAIL_Vertical-Videos_BIKE-TREAD-ROW_2023_BW_AdrianRow_002_Overlay.mp4"
            poster="https://images.ctfassets.net/6jnflt57iyzx/6ZH0XlFHBDD4qY3LZtFewi/c773c2accce263675a1af77118c7998c/230055_RETAIL_Vertical-Videos_BIKE-TREAD-ROW_2023_BW_AdrianRow_002_Overlay_Still001.jpg"
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
            playsInline
          ></Video>
        )}
        {curSection === "bike" && (
          <Video
            src="https://videos.ctfassets.net/6jnflt57iyzx/3sLfOzPbJAYLjeOntqcfiy/febd45423e0a9c3f2d17d410c65b1532/230055_RETAIL_Vertical-Videos_BIKE-TREAD-ROW_2023_BW_CamilaBike_002_Overlay.mp4"
            poster="https://images.ctfassets.net/6jnflt57iyzx/2iyQJJygc0bEQi0qsCcv6U/c1a30da66c6220dbc730d54926dc83ab/230055_RETAIL_Vertical-Videos_BIKE-TREAD-ROW_2023_BW_CamilaBike_002_Overlay_Still001.jpg"
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
            playsInline
          ></Video>
        )}
        {curSection === "tread" && (
          <Video
            src="https://videos.ctfassets.net/6jnflt57iyzx/fCwMoYV1qIKBc83yYoeda/51df819a37ab96f9049644f9af9b9751/230055_RETAIL_Vertical-Videos_BIKE-TREAD-ROW_2023_BW_MarcelTread_001_Overlay.mp4"
            poster="https://images.ctfassets.net/6jnflt57iyzx/2tU3mrwqkaECd97E5GFRBi/f09093564ea234c30625d51e1acf9a4c/230055_RETAIL_Vertical-Videos_BIKE-TREAD-ROW_2023_BW_MarcelTread_001_Overlay_Still001.jpg
"
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
            playsInline
          ></Video>
        )}

        {curSection === "strength" || (curSection === "yoga" && <Logo></Logo>)}

        <StartScreenBott>
          {/* XXX: hardcoded for row */}
          {curSection === "row" ||
          curSection === "bike" ||
          curSection === "tread" ? (
            <RowScreenSaverCopy section={curSection}>
              <div>{screenData && screenData.items[0].fields.title}</div>
            </RowScreenSaverCopy>
          ) : (
            <StartScreenTitle>
              {curSection !== "app" &&
                screenData &&
                screenData.items[0].fields.title}
            </StartScreenTitle>
          )}
          {selectedCategory && (
            <StartScreenBtnRect
              onMouseUp={() =>
                navigate(
                  `/${intlLocation}/${curSection}/main/${selectedCategory}`,
                  {
                    replace: false,
                  }
                )
              }
            >
              {curSection === "app"
                ? "Explore the app"
                : screenData && screenData.items[0].fields.ctaText}
            </StartScreenBtnRect>
          )}
        </StartScreenBott>
      </StartScreenCanvas>
    </MainCanvas>
  );
};
