import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Navigation } from "components/Navigation";
import { MainCanvas } from "components/UI/MainCanvas";

import { Images } from "constants/Images";
import styled from "styled-components";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { AppTableCell } from "@ecomm/typography/body";
// import { appHeadline } from "@ecomm/typography";

const TabBack = styled.a`
  display: none;

  ${ccIpadPortrait`
    position: absolute;
    left: 122px;
    top: 48px;
    z-index: 20;
    color: #888b93;
    display: block;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    -webkit-letter-spacing: 0.1em;
    -moz-letter-spacing: 0.1em;
    -ms-letter-spacing: 0.1em;
    letter-spacing: 0.1em;
    line-height: 1.375em;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.3;
    background-size: 10px;
    padding-left: 26px;
    background-image: url(${Images.globalBtnNavBackBtn});
    background-position: left center;
    background-repeat: no-repeat;
    /* test */
  `}
`;

const AppSubcontainer = styled.div`
  display: block;
  position: relative;
  padding: 83px 0px 150px 90px;
  background: #181a1d;
  min-height: 100vh;
`;

const AppTitle = styled.div`
  color: #cdff38;
  text-transform: uppercase;
  font-size: 30px;
  font-family: "Inter", sans-serif;
  position: absolute;
  left: 93px;
  top: 80px;
  font-weight: 700;
`;

// const AppTile = styled.div`
//   display: block;
//   position: relative;
//   width: 930px;
//   height: auto;
//   margin-bottom: 20px;
//   margin-top: 46px;
//   margin-left: -30px;
//   img {
//     width: 930px;
//     height: auto;
//   }
// `;

const AppTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  /* height: 2600px; */
  /* border: 1px solid #ccc; */
  margin-top: 100px;
  width: 876px;
  box-shadow: 5px 12px 25px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 12px 25px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 12px 25px -8px rgba(0, 0, 0, 0.75);
  margin-bottom: 100px;
`;

const AppTableHeader = styled.div`
  background: white;
  color: black;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 24px;
  height: 74px;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  padding-top: 24px;
`;

const AppTableCol = styled.div``;

const AppTableMainInfo = styled.div`
  min-height: 450px;
  padding: 20px;
  text-align: center;
  background-color: #181b1e;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
const AppTableMainPrice = styled.div`
  font-size: 46px;
  font-weight: 300;
  padding-top: 20px;
  padding-bottom: 16px;
`;
const AppTableMainCard = styled.div`
  color: #8a8d91;
  font-size: 22px;
`;
const AppTableMainDetail = styled.div`
  padding-top: 26px;
  font-size: 27px;
  line-height: 1.4em;
  font-weight: 300;
`;

const AppTableStrength = styled.div`
  ${AppTableCell}
`;
const AppTableCardio = styled.div`
  ${AppTableCell}
`;
const AppTablePrograms = styled.div`
  ${AppTableCell}
`;
const AppTableExclusive = styled.div`
  ${AppTableCell}
`;
const AppTableLiveCadence = styled.div`
  ${AppTableCell}
`;
const AppTableLeaderboard = styled.div`
  ${AppTableCell}
`;
const AppTableLiveClasses = styled.div`
  ${AppTableCell}
  border-bottom: 0px !important;
`;

export const Memberships = (props: RouteComponentProps) => {
  const standAlone = window.matchMedia("(display-mode: standalone)").matches;
  const { navigate } = props;

  const intlLocation = props?.location?.pathname?.split("/")[1];

  console.log(intlLocation, "intlLocation");

  return (
    <MainCanvas>
      {standAlone && (
        <TabBack
          onMouseUp={() =>
            navigate && navigate(`/${intlLocation}/ipad`, { replace: true })
          }
        >
          Home
        </TabBack>
      )}
      <AppSubcontainer>
        <AppTitle>Peloton &nbsp; App</AppTitle>

        {intlLocation === "en" ? (
          <AppTable>
            {/* <AppTableCol>
              <AppTableHeader>Peloton App Free</AppTableHeader>
              <AppTableMainInfo>
                <AppTableMainPrice>$0/mo</AppTableMainPrice>
                <AppTableMainCard>No credit card required</AppTableMainCard>
                <AppTableMainDetail>
                  Access to a limited collection of free classes.
                </AppTableMainDetail>
              </AppTableMainInfo>
              <AppTableStrength>
                Strength, cardio, yoga, outdoor running, & more
                <span>Limited free classes</span>
              </AppTableStrength>
              <AppTableCardio>
                Cardio equipment classes: Cycling, treadmill, & rowing
                <span>Limited free classes</span>
              </AppTableCardio>
              <AppTablePrograms>
                Programs and challenges
                <span>&mdash;</span>
              </AppTablePrograms>
              <AppTableExclusive>
                Exclusive classes
                <span>&mdash;</span>
              </AppTableExclusive>
              <AppTableLiveCadence>
                Cadence tracking
                <span>&mdash;</span>
              </AppTableLiveCadence>
              <AppTableLeaderboard>
                Leaderboard
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLeaderboard>
              <AppTableLiveClasses>
                Live classes
                <span>&mdash;</span>
              </AppTableLiveClasses>
            </AppTableCol> */}

            <AppTableCol>
              <AppTableHeader>Peloton App One</AppTableHeader>
              <AppTableMainInfo>
                <AppTableMainPrice>$12.99/mo</AppTableMainPrice>
                <AppTableMainCard>or $129/year (save $26)</AppTableMainCard>
                <AppTableMainDetail>
                  Access thousands of classes–from strength and meditation to
                  indoor cycling and outdoor walking.
                </AppTableMainDetail>
              </AppTableMainInfo>
              <AppTableStrength>
                Strength, cardio, yoga, outdoor running, & more
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableStrength>
              <AppTableCardio>
                Cardio equipment classes: Cycling, treadmill, & rowing
                <span>3 per month</span>
              </AppTableCardio>
              <AppTablePrograms>
                Programs and challenges
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTablePrograms>
              <AppTableExclusive>
                Exclusive classes
                <span>&mdash;</span>
              </AppTableExclusive>
              <AppTableLiveCadence>
                Cadence tracking
                <span>&mdash;</span>
              </AppTableLiveCadence>
              <AppTableLeaderboard>
                Leaderboard
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLeaderboard>
              <AppTableLiveClasses>
                Live classes
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLiveClasses>
            </AppTableCol>
            <AppTableCol>
              <AppTableHeader>Peloton App +</AppTableHeader>
              <AppTableMainInfo>
                <AppTableMainPrice>$24/mo</AppTableMainPrice>
                <AppTableMainCard>or $240/year (save $48)</AppTableMainCard>
                <AppTableMainDetail>
                  All App One offerings, plus take as many indoor bike,
                  treadmill, or rower classes as you want.
                </AppTableMainDetail>
              </AppTableMainInfo>
              <AppTableStrength>
                Strength, cardio, yoga, outdoor running, & more
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableStrength>
              <AppTableCardio>
                Cardio equipment classes: Cycling, treadmill, & rowing
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableCardio>
              <AppTablePrograms>
                Programs and challenges
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTablePrograms>
              <AppTableExclusive>
                Exclusive classes
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableExclusive>
              <AppTableLiveCadence>
                Cadence tracking
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLiveCadence>
              <AppTableLeaderboard>
                Leaderboard
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLeaderboard>
              <AppTableLiveClasses>
                Live classes
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLiveClasses>
            </AppTableCol>
          </AppTable>
        ) : (
          <AppTable>
            {/* <AppTableCol>
              <AppTableHeader>Peloton App Free</AppTableHeader>
              <AppTableMainInfo>
                <AppTableMainPrice>$0/mo</AppTableMainPrice>
                <AppTableMainCard>No credit card required</AppTableMainCard>
                <AppTableMainDetail>
                  Access to a limited collection of free classes.
                </AppTableMainDetail>
              </AppTableMainInfo>
              <AppTableStrength>
                Strength, cardio, yoga, outdoor running, & more
                <span>Limited free classes</span>
              </AppTableStrength>
              <AppTableCardio>
                Cardio equipment classes: Cycling, treadmill, & rowing
                <span>Limited free classes</span>
              </AppTableCardio>
              <AppTablePrograms>
                Programs and challenges
                <span>&mdash;</span>
              </AppTablePrograms>
              <AppTableExclusive>
                Exclusive classes
                <span>&mdash;</span>
              </AppTableExclusive>
              <AppTableLiveCadence>
                Cadence tracking
                <span>&mdash;</span>
              </AppTableLiveCadence>
              <AppTableLeaderboard>
                Leaderboard
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLeaderboard>
              <AppTableLiveClasses>
                Live classes
                <span>&mdash;</span>
              </AppTableLiveClasses>
            </AppTableCol> */}
            <AppTableCol>
              <AppTableHeader>Peloton App One</AppTableHeader>
              <AppTableMainInfo>
                <AppTableMainPrice>$16.99/mo</AppTableMainPrice>
                <AppTableMainCard>after 30-day trial</AppTableMainCard>
                <AppTableMainDetail>
                  Access thousands of classes–from strength and meditation to
                  indoor cycling and outdoor walking.
                </AppTableMainDetail>
              </AppTableMainInfo>
              <AppTableStrength>
                Strength, cardio, yoga, outdoor running, & more
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableStrength>
              <AppTableCardio>
                Cardio equipment classes: Cycling, treadmill, & rowing
                <span>3 per month</span>
              </AppTableCardio>
              <AppTablePrograms>
                Programs and challenges
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTablePrograms>
              <AppTableExclusive>
                Exclusive classes
                <span>&mdash;</span>
              </AppTableExclusive>
              <AppTableLiveCadence>
                Cadence tracking
                <span>&mdash;</span>
              </AppTableLiveCadence>
              <AppTableLeaderboard>
                Leaderboard
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLeaderboard>
              <AppTableLiveClasses>
                Live classes
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLiveClasses>
            </AppTableCol>
            <AppTableCol>
              <AppTableHeader>Peloton App +</AppTableHeader>
              <AppTableMainInfo>
                <AppTableMainPrice>$30/mo</AppTableMainPrice>
                <AppTableMainCard>after 30-day trial</AppTableMainCard>
                <AppTableMainDetail>
                  All App One offerings, plus take as many indoor bike,
                  treadmill, or rower classes as you want.
                </AppTableMainDetail>
              </AppTableMainInfo>
              <AppTableStrength>
                Strength, cardio, yoga, outdoor running, & more
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableStrength>
              <AppTableCardio>
                Cardio equipment classes: Cycling, treadmill, & rowing
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableCardio>
              <AppTablePrograms>
                Programs and challenges
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTablePrograms>
              <AppTableExclusive>
                Exclusive classes
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableExclusive>
              <AppTableLiveCadence>
                Cadence tracking
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLiveCadence>
              <AppTableLeaderboard>
                Leaderboard
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLeaderboard>
              <AppTableLiveClasses>
                Live classes
                <span>
                  <img alt="" src={Images.appCheckCircle} />
                </span>
              </AppTableLiveClasses>
            </AppTableCol>
          </AppTable>
        )}
      </AppSubcontainer>
      <Navigation></Navigation>
      <a href="/" className="hidden-btn">
        &nbsp;&nbsp;
      </a>
    </MainCanvas>
  );
};
