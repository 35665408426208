import { ACCESS_TOKEN, REFRESH_TOKEN } from './constants';
import { getCommercetoolsClientConfig } from './config';
import {
  getSessionStoredValue,
  removeSessionStoredValue,
  setSessionStoredValue,
} from './storageService';

const { clientId, clientSecret, projectKey, region } = getCommercetoolsClientConfig();

const UNAUTHORIZED = 401;
const FETCH_RETRY_COUNT = 5;

let currentCount = 0;

const scope = `view_products:${projectKey}`;
const authUrl = `https://auth.${region}.gcp.commercetools.com`;

const authClientSecret = btoa(`${clientId}:${clientSecret}`);

const headers = {
  authorization: `Basic ${authClientSecret}`,
  'content-type': 'application/x-www-form-urlencoded',
};

type TokenInfoType = {
  access_token: string;
  refresh_token: string;
};
export const saveToken = ({ access_token, refresh_token }: TokenInfoType): string => {
  setSessionStoredValue(ACCESS_TOKEN, access_token);
  setSessionStoredValue(REFRESH_TOKEN, refresh_token);

  return access_token;
};

export const resetToken = (): void => {
  removeSessionStoredValue(ACCESS_TOKEN);
  removeSessionStoredValue(REFRESH_TOKEN);
};

export const getTokenImpl = async (fetchFn: typeof global.fetch) => {
  const encodedScope = encodeURI(scope);
  const fetchToken = await fetchFn(`${authUrl}/oauth/token`, {
    headers,
    body: `grant_type=client_credentials&scope=${encodedScope}`,
    method: 'POST',
  });

  const tokenInfo = await fetchToken.json();

  return tokenInfo;
};

export const getToken = async (): Promise<string | undefined> => {
  const token = getSessionStoredValue(ACCESS_TOKEN);

  if (token) {
    return Promise.resolve(token);
  }

  try {
    const tokenInfo = await getTokenImpl(global.fetch);

    return saveToken(tokenInfo);
  } catch (error) {
    return handleError(error as Error);
  }
};

export const refreshToken = async (): Promise<string | undefined> => {
  const token = getSessionStoredValue(REFRESH_TOKEN);
  if (!token) {
    resetToken();
    return Promise.reject('no refresh token');
  }

  try {
    const fetchRefreshToken = await fetch(`${authUrl}/oauth/token`, {
      headers,
      body: `grant_type=refresh_token&refresh_token=${token}`,
      method: 'POST',
    });

    const refreshTokenInfo = await fetchRefreshToken.json();
    if (
      [400, 401, 403].includes(refreshTokenInfo?.statusCode) ||
      !!refreshTokenInfo?.error
    ) {
      return Promise.reject('token refresh failed');
    }
    return saveToken(refreshTokenInfo);
  } catch (error) {
    resetToken();
    return handleError(error as Error);
  }
};

const fetchWithToken = async (url: string, options: RequestInit): Promise<Response> => {
  try {
    const token = await getToken();
    const authToken = token?.replace(/"/g, '');
    const response = await fetch(url, {
      ...options,
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    });

    if (response.status === UNAUTHORIZED && currentCount < FETCH_RETRY_COUNT) {
      await refreshToken();
      currentCount++;

      return await fetchWithToken(url, options);
    }

    return response;
  } catch (error) {
    resetToken();
    return handleError(error as Error);
  }
};

const handleError = (error: Error) => {
  console.error('commercetools: fetchWithToken error', error);
  return Promise.reject(error);
};
export default fetchWithToken;
