import React from "react";
import { RouteComponentProps } from "@reach/router";
import { MainCanvas } from "components/UI/MainCanvas";
import styled from "styled-components";
import {
  tsLogo,
  tsWidth,
  tsHeight,
  startScreenBtn,
  anim1,
} from "@ecomm/typography";
import { Images } from "../constants/Images";
import { tsbgblack1, white } from "@ecomm/colors";

const StartScreenCanvas = styled.div`
  ${tsWidth};
  ${tsHeight};
  /* background-color: ${tsbgblack1}; */
  background-color: #101017;
  position: relative;
  color: ${white};
  display: block;
  /* background-image: url(${Images.globalTopGlow}); */

  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
`;

const Logo = styled.div`
  /* background-image: url(${Images.globalGlowLogo}); */
  ${tsLogo};
`;

const Space = styled.div`
  padding-top: 250px;
`;

const StartScreenBtn = styled.a`
  ${startScreenBtn};
  margin-bottom: 30px;
  ${anim1};
`;

export const TabletStartScreen = (props: RouteComponentProps) => {
  const navigate = props.navigate; // useNavigate();

  const intlLocation = props?.location?.pathname?.split("/")[1];

  return (
    <MainCanvas>
      <StartScreenCanvas>
        <Logo></Logo>
        <Space></Space>

        <StartScreenBtn
          onMouseUp={() =>
            navigate &&
            navigate(`/${intlLocation}/bike/main/bike`, {
              replace: true,
            })
          }
        >
          Bike
        </StartScreenBtn>
        <StartScreenBtn
          onMouseUp={() =>
            navigate &&
            navigate(`/${intlLocation}/tread/main/tread`, {
              replace: true,
            })
          }
        >
          Tread
        </StartScreenBtn>
        <StartScreenBtn
          onMouseUp={() =>
            navigate &&
            navigate(`/${intlLocation}/yoga/main/alacarte`, {
              replace: true,
            })
          }
        >
          Yoga
        </StartScreenBtn>
        <StartScreenBtn
          onMouseUp={() =>
            navigate &&
            navigate(`/${intlLocation}/strength/main/alacarte`, {
              replace: true,
            })
          }
        >
          Strength
        </StartScreenBtn>
      </StartScreenCanvas>
    </MainCanvas>
  );
};
