// import type { ProductItemOfBundle } from '@ecomm/pg-shop-accessories-display/models';
// import type {
//   CfuAttributes,
//   CfuProductItem,
//   CfuVariant,
// } from '@ecomm/shop-configuration';
import { formatProductId } from '../constants';
import { mapBundleProductAttrsToCfuProductAttrs } from './mapBundleProductAttrsToCfuProductAttrs';

export const mapBundleProductsToCfuAccessories = (
  products: any[],
): any[] =>
  products.map(product => {
    const attributes: any = mapBundleProductAttrsToCfuProductAttrs(product);

    const {
      id,
      slug,
      name,
      description,
      images,
      availability,
      variants,
      price,
      displayName,
    } = product;

    const skuVariants: any[] = variants.map((variant: any) => ({
      id: formatProductId(variant.legacyOptionId),
      slug: variant.key,
      name: variant.key,
      skus: [variant.sku],
      description: variant.description || '',
      price: variant.price,
      configurations: variant.configurations,
    }));

    return {
      id: formatProductId(id),
      slug,
      name: displayName || name,
      price,
      description: description || '',
      image: images[0],
      availability,
      attributes,
      variants: skuVariants,
    };
  });
