export const COMMERCETOOLS_REGION = "us-central1";
export const COMMERCETOOLS_PROJECT_KEY = "peloton-ecomm-stage";
export const COMMERCETOOLS_CLIENT_ID = "c0n3bclb3lWE6cYErhiALEdm";
export const COMMERCETOOLS_CLIENT_SECRET = "zMTiSswAuoWnQITw4mJ0xdFYeChoqhZi";
export const COMMERCETOOLS_API_URL = "https://api.us-central1.gcp.commercetools.com";
export const COMMERCETOOLS_AUTH_URL = "https://auth.us-central1.gcp.commercetools.com";

export const COMMERCETOOLS_WEB_CHANNEL_ID = "daa0b39b-9116-4a1b-bf7d-bc8f3a04dd23";
export const COMMERCETOOLS_MARKDOWN_CHANNEL_ID = "27925f30-5bed-4214-90df-7192f36f17d0";

export const COMMERCETOOLS_APPAREL_CATEGORY_ID = "292c2eef-692a-4d64-86d9-91f1cae6e70e";

export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_US = "85ef98e7-ed83-469e-9aaf-8528a32921c0";
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_CA = "85ef98e7-ed83-469e-9aaf-8528a32921c0";
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_GB = "8e9118ad-9d7c-43c3-835b-b8cc5c87cca7";
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_AU = "4c006974-3b3a-4d6a-832f-bdd8df17aa7c";
export const COMMERCETOOLS_INVENTORY_CHANNEL_ID_DE = "46197ae2-a580-4e82-b724-a0d13b8898ee";

export const COMMERCETOOLS_REGION_PROD = "us-central1";
export const COMMERCETOOLS_PROJECT_KEY_PROD = "peloton-ecomm-prod";
export const COMMERCETOOLS_CLIENT_ID_PROD = "jhX_T4RKvJxtwObZaYIex0Cb";
export const COMMERCETOOLS_CLIENT_SECRET_PROD = "RmsWac0xbCiHYZhqVCnvT0Oa7lnBubaU";

export const ACCESS_TOKEN = 'ct_access_token';
export const REFRESH_TOKEN = 'ct_refresh_token';

export enum ApiEnvs {
  Qa1 = 'qa1',
  Qa2 = 'qa2',
  Prod = 'api',
  ProdTest = 'api',
  Local = 'local-api',
  Test = 'localhost',
}

export const CT_BIKE_SLUGS = [
  "bike-package",
  "bike-starter-package-us",
  "bike-ultimate-package-us",
]

export const CT_BIKE_SLUGS_CA = [
  "bike-basics-package-ca",
  "bike-starter-package-ca",
  "bike-ultimate-package-ca",
]

export const CT_BIKE_PLUS_SLUGS = [
  "opc-pkg-bp-8",
  "opc-pkg-bp-8-250",
  "bike-plus-basics-package-us",
  "bike-plus-starter-package-us",
  "bike-plus-ultimate-package-us"
]

export const CT_BIKE_PLUS_SLUGS_CA = [
  "opc-pkg-bp-8",
  "bike-plus-basics-package-ca",
  "bike-plus-starter-package-ca",
  "bike-plus-ultimate-package-ca"
]

export const CT_TREAD_SLUGS = [
  "tread-basics-package-us",
  "tread-starter-package-us",
  "tread-ultimate-package-us",
]

export const CT_TREAD_SLUGS_CA = [
  "tread-basics-package-ca",
  "tread-starter-package-ca",
  "tread-ultimate-package-ca",
]

type CT_SLUGS_MAP = {
  [localekey: string]: {
    bike: string[],
    bikePlus: string[],
    tread: string[],
  };
}

export const CT_SLUGS_MAP: CT_SLUGS_MAP = {
  en: {
    bike: CT_BIKE_SLUGS,
    bikePlus: CT_BIKE_PLUS_SLUGS,
    tread: CT_TREAD_SLUGS,
  },
  ca: {
    bike: CT_BIKE_SLUGS_CA,
    bikePlus: CT_BIKE_PLUS_SLUGS_CA,
    tread: CT_TREAD_SLUGS_CA,
  }
}

export enum AccessoryKeys {
  SIZE = 'size',
  WARRANTY_SIZE = 'warranty-size',
  SIZE_LABEL = 'size-label',
  LEGACY_OPTION_ID = 'legacy-option-id',
  LEGACY_PRODUCT_ID = 'legacy-product-id',
  SORT_ORDER = 'sort-order',
  CHANNEL_AVAILABILITY = 'channel-availability',
  SALES_STATUS = 'sales-status',
  DISPLAY_NAME = 'product-display-name',
  VARIANT_DESCRIPTION = 'variant-description',
}

export enum AccessoryBundleKeys {
  PRODUCTS = 'bundle-products-set',
  LEGACY_ID = 'legacy-id',
  CHANNEL_AVAILABILITY = 'channel-availability',
  SALES_STATUS = 'sales-status',
  DISCOUNT = 'discounts',
  IS_CONFIGURABLE = 'is-configurable',
  PRODUCT_REFERENCES = 'bundle-products-ref',
  DISPLAY_NAME = 'product-display-name',
  LEGACY_EQUIPMENT_TYPE = 'legacy-equipment-type',
  SUBSCRIPTION = 'subscription-ref',
  UPSELLS = 'upsell-products-ref',
}

export const PRODUCT_REF = 'product-ref';
export const CFU_WARRANTY = 'warranty';
export const CFU_PRODUCT = 'cfu-product';
export const CFU_ACCESSORIES = 'accessories';

export const CONFIGURABLE_ATTRIBUTES: string[] = [
  AccessoryKeys.SIZE,
  AccessoryKeys.WARRANTY_SIZE,
];

enum SubscriptionKeys {
  SUBSCRIPTION_SIZE = 'subscription-size',
  VARIANT_NAME = 'variant-name',
}

export const SUBSCRIPTION_CONFIGURABLE_ATTRIBUTES: string[] = [
  SubscriptionKeys.SUBSCRIPTION_SIZE,
  SubscriptionKeys.VARIANT_NAME,
  ...CONFIGURABLE_ATTRIBUTES,
];

export const formatProductId = (id: string): string => {
  if (id.length !== 32) {
    return id;
  }

  return `${id.substring(0, 8)}-${id.substring(8, 12)}-${id.substring(
    12,
    16,
  )}-${id.substring(16, 20)}-${id.substring(20)}`;
};