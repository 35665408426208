import { createHttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import fetchWithToken from "./fetchWithToken";
import { getCommercetoolsClientConfig } from "./config";

export const toCommercetoolsAuthUrl = (): string => {
  const { region, projectKey } = getCommercetoolsClientConfig();

  return `https://auth.${region}.gcp.commercetools.com/oauth/${projectKey}/anonymous/token?grant_type=client_credentials&scope=view_products:${projectKey}`;
};

export const toCommercetoolsAuthToken = (): string => {
  const { clientId, clientSecret } = getCommercetoolsClientConfig();
  return `Basic ${btoa(`${clientId}:${clientSecret}`)}`;
};

export const toCommercetoolsApiUrl = (): string => {
  const { region, projectKey } = getCommercetoolsClientConfig();

  return `https://api.${region}.gcp.commercetools.com/${projectKey}/graphql`;
};

export const commercetoolsHttpLink = createHttpLink({
  uri: toCommercetoolsApiUrl(),
  // @ts-expect-error
  fetch: fetchWithToken,
});

export const toCommercetoolsClient = (name?: string) => {
  const client = new ApolloClient({
    name: 'commercetools',
    link: commercetoolsHttpLink,
    cache: new InMemoryCache({
      addTypename: true,
    }),
    ssrMode: typeof window === 'undefined',
  });
  return client;
}

export const IS_TEST_ENV = window.location.hostname.includes("test") || window.location.hostname.includes("local");