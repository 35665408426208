// @ts-nocheck
import type { ProductItemOfBundle } from '@ecomm/pg-shop-accessories-display/models';
import { CFU_ACCESSORIES, CFU_PRODUCT, CFU_WARRANTY } from '../constants';

export type CfuProducts = {
  [CFU_WARRANTY]: ProductItemOfBundle[];
  [CFU_PRODUCT]: ProductItemOfBundle[];
  [CFU_ACCESSORIES]: ProductItemOfBundle[];
};

export const getCfuProducts = (products: ProductItemOfBundle[]): CfuProducts =>
  products.reduce<CfuProducts>(
    (acc, product: ProductItemOfBundle) => {
      const { productType } = product;

      if (productType === CFU_PRODUCT) acc[CFU_PRODUCT].push(product);
      else if (productType === CFU_WARRANTY) acc[CFU_WARRANTY].push(product);
      else acc[CFU_ACCESSORIES].push(product);

      return acc;
    },
    {
      [CFU_PRODUCT]: [],
      [CFU_WARRANTY]: [],
      [CFU_ACCESSORIES]: [],
    },
  );
