import { mapCTCFUtoKronosCFU } from 'commercetools/mappers/mapCTCFUtoKronosCFU';
import { useCommercetoolsClient } from '../provider';
import { useSearchAnyProductsByKeysQuery } from '../queries/SearchAnyProductsByKeys.generated';

export type BucketedProducts = {
  accessories: Record<string, unknown>;
  bundles: Record<string, unknown>;
  cfuPackages: Record<string, unknown>;
};

export const extractProductDetails = (data: any, locale: string) => {
  const results = data.products.results;
  // @ts-expect-error
  return results.map(product => {
    const { name, slug, description } = product.masterData.current;
    const amount = product.masterData.current.masterVariant?.price?.value.centAmount || 0;
    return { id: product.id, name, description, slug, price: { amount } };
  });
}

const useProductsByKeys = (keys: string[], locale?: string) => {
  let country = 'us';
  let currency = 'USD';
  if (locale === 'ca') {
    country = 'ca';
    currency = 'CAD';
  }
  const acceptLanguage = ['en-US', 'en-US'];
  const client = useCommercetoolsClient();

  const { loading, error, data } = useSearchAnyProductsByKeysQuery({
    fetchPolicy: 'cache-first',
    variables: {
      where: toWhereClause(keys),
      acceptLanguage,
      currency,
      country,
    },
    client,
    notifyOnNetworkStatusChange: true,
  });

  if (data && keys.length === 1) {
    // @ts-expect-error
    const res = mapCTCFUtoKronosCFU(data?.products.results[0], locale);
    return { loading, error, products: [res] };
  }
  const resp = (data && extractProductDetails(data, locale as string)) || undefined;
  return { loading, error, products: resp };
};

export const toWhereClause = (keys: string[]) =>
  keys.map(key => `key="${key}"`).join(' or ');

export default useProductsByKeys;
