import React, { useRef, useState, useEffect, ReactPortal } from "react";
import { RouteComponentProps } from "@reach/router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, { Settings } from "react-slick";
import styled from "styled-components";
import { Images } from "constants/Images";
import { SlideArrow } from "./SlideArrow";
import { PackageSlidesData } from "types/package";
import {
  sliderBigTitle,
  tsWidth,
  tsHeight,
  sliderNeedTitle,
  sliderDescr,
} from "@ecomm/typography";
import { PackageSlideList } from "./PackageSlideList";
import { PackageSlideMain } from "./PackageSlideMain";
import { SlideViewDetails } from "./SlideViewDetails";
import { Overlay } from "./Overlay";
import { useSpring, animated } from "react-spring";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";

const Thumbnail = styled.img`
  border: 1px solid #777a81;
  border-radius: 5px;
`;

const Item = styled.div`
  position: relative;
  ${tsWidth};
  ${tsHeight};
  /* background-color: #060607; */
  /* background-image: url(${Images.globalTopGlow});
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat; */
`;

const Title = styled.div`
  ${sliderBigTitle};
  ${tsWidth};
  ${tsHeight};
  position: absolute;
  top: 230px;
  left: 0px;
  z-index: 0;
  display: block;
`;

const SlideImage = styled.img`
  width: 100%;
  height: auto;
`;
const slideAnimated = animated.div as unknown as ReactPortal;
// @ts-expect-error
const SlideImageAnim = styled(slideAnimated)`
  opacity: 0;
  margin: 0 auto;
  display: block;
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 480px;
  ${ccIpadPortrait`
    top: 170px !important;
  `}
`;
// @ts-expect-error
const SlideDetailsAnim = styled(slideAnimated)`
  position: relative;
  opacity: 0;
  z-index: 2;
`;

const WhyYouNeedIt = styled.div`
  ${sliderNeedTitle};
  ${tsWidth};
  top: 1190px;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 1;
  ${ccIpadPortrait`
    top: 710px;
  `}
`;

const Description = styled.div`
  ${sliderDescr};
  width: 640px;
  top: 1255px;
  position: absolute;
  left: 220px;
  z-index: 1;
  ${ccIpadPortrait`
    top: 740px;
    width: auto;
    left: 160px;
    right: 160px;
    font-size: 16px;
  `}
`;
// @ts-expect-error
const OverlayAnim = styled(slideAnimated)`
  position: relative;
  z-index: 1001;
`;
// @ts-expect-error
const DotsAnim = styled(slideAnimated)`
  position: relative;
`;

interface Props extends RouteComponentProps<{}> {
  data: PackageSlidesData;
  showTitle: (val: boolean) => void;
  locationArray: string[];
}

const SliderElement = Slider as unknown as Slider;

export const PackageSlides = (props: Props) => {
  const curCategory = props.locationArray[4];
  let product = "BIKE";
  switch (curCategory) {
    case "tread":
      product = "TREAD";
      break;
    case "treadplus":
      product = "TREAD+";
      break;
    case "bikeplus":
      product = "BIKE+";
      break;
    case "row":
      product = "ROW";
      break;
    default:
      break;
  }
  const { data, showTitle } = props;

  const products = data.items;
  const numSlides = products && products.length + 2;
  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [toggle, setToggle] = useState(false);
  const [detailsToggle, setDetailsToggle] = useState(false);
  const [dir, setDir] = useState<number>(-1);
  const [isSwiping, setIsSwiping] = useState(false);

  const handleChangeSlide = (currentSlide: number) => {
    const leftArrowVisible = currentSlide !== 0;
    const rightArrowVisible = currentSlide <= products.length;
    setShowLeftArrow(leftArrowVisible);
    setShowRightArrow(rightArrowVisible);
    currentSlide === 0 ? showTitle(false) : showTitle(true);
  };

  const dotsAnimSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: {
      delay: 4000,
    },
  });

  const sliderRef = useRef<Slider>(null);
  const settings: Settings = {
    customPaging: (i: number) => {
      let iconUrl;
      let iconUrlActive = null;
      switch (i) {
        case 0:
          iconUrl = data.iconUrl;
          iconUrlActive = data.iconUrlActive;
          break;
        case numSlides - 1:
          iconUrl = Images["chicListDef"];
          iconUrlActive = Images["chicListActive"];
          break;
        default:
          iconUrl = products[i - 1].iconUrl;
          iconUrlActive = products[i - 1].iconUrlActive;
      }
      let imgSrc = iconUrl;
      if (currentSlide === i) {
        imgSrc = iconUrlActive;
      }
      return <Thumbnail src={imgSrc} />;
    },
    dots: true,
    appendDots: (dots) => (
      <DotsAnim style={dotsAnimSpring}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </DotsAnim>
    ),
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide: number) => {
      // console.log('afterChange', isSwiping);
      if (!isSwiping) setToggle(true);
      setCurrentSlide(currentSlide);
      handleChangeSlide(currentSlide);
    },
    beforeChange: (currentSlide: number) => {
      // console.log('beforeChange', isSwiping);
      if (!isSwiping) {
        setToggle(false);
        setDetailsToggle(false);
      }
    },
    onSwipe: () => {
      setIsSwiping(true);
      // console.log('onSwipe', isSwiping);
    },
  };

  const nextSlide = () => {
    // console.log('nextSlide');
    setIsSwiping(false);
    setDir(1);
    setToggle(false);
    setDetailsToggle(false);
  };

  const prevSlide = () => {
    // console.log('prevSlide');
    setIsSwiping(false);
    setDir(-1);
    setToggle(false);
    setDetailsToggle(false);
  };

  const anim = useSpring({
    opacity: toggle ? 1 : 0,
    top: toggle ? "380px" : "420px",
    onRest: () => {
      if (toggle) {
        setDetailsToggle(toggle);
      } else {
        sliderRef.current?.slickGoTo(currentSlide + dir);
      }
    },
  });

  const detailsAnim = useSpring({
    opacity: detailsToggle ? 1 : 0,
  });

  const overlayAnimSpring = useSpring({
    opacity: showOverlay ? 1 : 0,
  });

  useEffect(() => {
    setShowRightArrow((numSlides as number) > 1);
  }, [numSlides]);

  return (
    <div>
      <OverlayAnim style={overlayAnimSpring}>
        {showOverlay && (
          <Overlay
            title={products[currentSlide - 1].title}
            details={
              products[currentSlide - 1].details &&
              products[currentSlide - 1].details
            }
            image={products[currentSlide - 1].imageUrl}
            hideOverlay={toggleOverlay}
          ></Overlay>
        )}
      </OverlayAnim>

      <div key={"slider"}>
        {showLeftArrow && (
          <SlideArrow type="prev" onMouseUp={() => prevSlide()} />
        )}
        {/* @ts-expect-error */}
        <SliderElement {...settings} ref={sliderRef}>
          <PackageSlideMain
            slideMainUrl={data.slideMainUrl}
            title={data.title}
            description={data.description}
            price={data.price}
            product={product}
            slug={data.slug}
          ></PackageSlideMain>

          {products.map((item: any, i: number) => {
            const name = item.title;
            const slideUrl = item.slideUrl;
            const description = item.description;
            const detailOffsetX = item.detailOffsetX;
            const detailOffsetY = item.detailOffsetY;

            return (
              <div key={i}>
                <Item>
                  <Title>{name}</Title>
                  <SlideImageAnim style={anim}>
                    <SlideImage alt={name} src={slideUrl} />
                  </SlideImageAnim>
                  <WhyYouNeedIt>Why You Need It</WhyYouNeedIt>
                  <Description>{description}</Description>
                  <SlideDetailsAnim style={detailsAnim}>
                    <SlideViewDetails
                      left={detailOffsetX}
                      top={detailOffsetY}
                      onMouseUp={() => toggleOverlay()}
                    ></SlideViewDetails>
                  </SlideDetailsAnim>
                </Item>
              </div>
            );
          })}
          <div key="list">
            <PackageSlideList
              items={data.items}
              listOnlyItems={data.listOnlyItems}
            ></PackageSlideList>
          </div>
        </SliderElement>
        {showRightArrow && (
          <SlideArrow type="next" onMouseUp={() => nextSlide()} />
        )}
      </div>
    </div>
  );
};
