// @ts-nocheck
// import type { AccessoryBundleCommercetools } from '@ecomm/pg-shop-accessories-display/models';
export const calculateBundleDiscount = (
  bundlePrice: number,
  products: AccessoryBundleCommercetools['products'],
): number => {
  const bundleHasVariablePricing = products.some(
    (product) =>
      product.variants.length > 1 && // if there's only 1 variant, there can't be a price range
      product.variants.some(variant => variant.price.amount !== product.price.amount),
  );

  if (bundleHasVariablePricing) return 0;

  const sumOfAllProductPrices = products.reduce<number>(
    (acc, product) => acc + product.price.amount,
    0,
  );

  return sumOfAllProductPrices - bundlePrice;
};
