import { COMMERCETOOLS_REGION, COMMERCETOOLS_PROJECT_KEY, COMMERCETOOLS_CLIENT_ID, COMMERCETOOLS_CLIENT_SECRET, COMMERCETOOLS_REGION_PROD, COMMERCETOOLS_PROJECT_KEY_PROD, COMMERCETOOLS_CLIENT_ID_PROD, COMMERCETOOLS_CLIENT_SECRET_PROD } from "./constants";

export type CommercetoolsClientConfig = {
  region: string;
  projectKey: string;
  clientId: string;
  clientSecret: string;
};

export const getCommercetoolsClientConfig = (): CommercetoolsClientConfig => {
  if (typeof window === 'undefined') {
    return {
      region: COMMERCETOOLS_REGION!,
      projectKey: COMMERCETOOLS_PROJECT_KEY!,
      clientId: COMMERCETOOLS_CLIENT_ID!,
      clientSecret: COMMERCETOOLS_CLIENT_SECRET!,
    };
  }
  if (process.env.VERCEL_ENV === 'production') {
    return {
      region: (COMMERCETOOLS_REGION_PROD || COMMERCETOOLS_REGION)!,
      projectKey: (COMMERCETOOLS_PROJECT_KEY_PROD || COMMERCETOOLS_PROJECT_KEY)!,
      clientId: (COMMERCETOOLS_CLIENT_ID_PROD || COMMERCETOOLS_CLIENT_ID)!,
      clientSecret: (COMMERCETOOLS_CLIENT_SECRET_PROD || COMMERCETOOLS_CLIENT_SECRET)!,
    };
  }
  return {
    region: COMMERCETOOLS_REGION!,
    projectKey: COMMERCETOOLS_PROJECT_KEY!,
    clientId: COMMERCETOOLS_CLIENT_ID!,
    clientSecret: COMMERCETOOLS_CLIENT_SECRET!,
  };
};