import React, { useRef, useState, useEffect, ReactPortal } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider, { Settings } from "react-slick";
import styled from "styled-components";
import { Images } from "constants/Images";
import { SlideArrow } from "./SlideArrow";

import {
  sliderBigTitle,
  tsWidth,
  tsHeight,
  sliderNeedTitle,
  sliderDescr,
  SliderChicTit,
} from "@ecomm/typography";
import { SlideViewDetails } from "./SlideViewDetails";
import { Overlay } from "./Overlay";
import { useSpring, animated } from "react-spring";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { AccessoriesItem } from "types/accessories";

const Item = styled.div`
  position: relative;
  ${tsWidth};
  ${tsHeight};
  /* background-color: #060607; */
  /* background-image: url(${Images.globalTopGlow});
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat; */
`;

const Title = styled.div`
  ${sliderBigTitle};
  ${tsWidth};
  ${tsHeight};
  position: absolute;
  top: 230px;
  left: 0px;
  z-index: 0;
  display: block;
`;

const SlideImage = styled.img`
  /* border: 2px solid blue; */
  width: 100%;
  height: auto;
`;
const slideAnimated = animated.div as unknown as ReactPortal;
// @ts-expect-error
const SlideImageAnim = styled(slideAnimated)`
  opacity: 0;
  margin: 0 auto;
  display: block;
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 480px;
  ${ccIpadPortrait`
    top: 170px !important;
  `}
`;
// @ts-expect-error
const SlideDetailsAnim = styled(slideAnimated)`
  position: relative;
  opacity: 0;
  z-index: 2;
`;

const WhyYouNeedIt = styled.div`
  ${sliderNeedTitle};
  ${tsWidth};
  top: 1190px;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 1;
  ${ccIpadPortrait`
    top: 710px;
  `}
`;

const Description = styled.div`
  ${sliderDescr};
  width: 640px;
  top: 1255px;
  position: absolute;
  left: 220px;
  z-index: 1;
  ${ccIpadPortrait`
    top: 740px;
    width: auto;
    left: 160px;
    right: 160px;
    font-size: 16px;
  `}
`;

const SlideItemPrice = styled.div`
  padding-top: 60px;
  ${SliderChicTit};
  color: white;
  font-size: 28px;
`;
// @ts-expect-error
const OverlayAnim = styled(slideAnimated)`
  position: relative;
  z-index: 1001;
`;

interface Props extends RouteComponentProps<{}> {
  data: AccessoriesItem[];
}

export const AccessoriesSlides = (props: Props) => {
  const { data } = props;
  const [toggle, setToggle] = useState(false);
  const [detailsToggle, setDetailsToggle] = useState(false);
  const [dir, setDir] = useState<number>(-1);
  const numSlides = data.length;
  const location = useLocation();
  const initalSlide = location.pathname.slice(
    location.pathname.lastIndexOf("/") + 1
  );

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };
  const SliderElement = Slider as unknown as Slider;
  const sliderRef = useRef<typeof SliderElement>(null);
  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: parseInt(initalSlide),
    onInit: () => {
      setToggle(true);
    },
    afterChange: (currentSlide: number) => {
      setToggle(true);
      setCurrentSlide(currentSlide);
      handleChangeSlide(currentSlide);
    },
    beforeChange: (currentSlide: number) => {
      setToggle(false);
      setDetailsToggle(false);
    },
  };

  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentSlide, setCurrentSlide] = useState<number>(
    parseInt(initalSlide)
  );

  const handleChangeSlide = (currentSlide: number) => {
    const leftArrowVisible = currentSlide !== 0;
    const rightArrowVisible = currentSlide <= numSlides - 2;
    setShowLeftArrow(leftArrowVisible);
    setShowRightArrow(rightArrowVisible);
  };

  const nextSlide = () => {
    setDir(1);
    setToggle(false);
    setDetailsToggle(false);
  };

  const prevSlide = () => {
    setDir(-1);
    setToggle(false);
    setDetailsToggle(false);
  };

  const anim = useSpring({
    opacity: toggle ? 1 : 0,
    top: toggle ? "380px" : "420px",
    onRest: () => {
      if (toggle) {
        setDetailsToggle(toggle);
      } else {
        sliderRef.current?.slickGoTo(currentSlide + dir);
      }
    },
  });

  const detailsAnim = useSpring({
    opacity: detailsToggle ? 1 : 0,
  });
  const overlayAnimSpring = useSpring({
    opacity: showOverlay ? 1 : 0,
  });

  useEffect(() => {
    setShowRightArrow(
      (numSlides as number) > 1 && currentSlide <= numSlides - 2
    );
    setShowLeftArrow(currentSlide > 0);
  }, [numSlides, currentSlide]);

  return (
    <div className="relly">
      <OverlayAnim style={overlayAnimSpring}>
        {showOverlay && (
          <Overlay
            title={data[currentSlide].title}
            details={data[currentSlide].details && data[currentSlide].details}
            image={data[currentSlide].imageUrl}
            hideOverlay={toggleOverlay}
          ></Overlay>
        )}
      </OverlayAnim>

      <div key={"slider"}>
        {showLeftArrow && (
          <SlideArrow type="prev" onMouseUp={() => prevSlide()} />
        )}
        {/* @ts-expect-error */}
        <SliderElement {...settings} ref={sliderRef}>
          {data.map((item, i) => (
            <div key={i}>
              <Item>
                <Title>{item.title}</Title>
                <SlideImageAnim style={anim}>
                  <SlideImage alt="" src={item.slideUrl} />
                </SlideImageAnim>
                <WhyYouNeedIt>Why You Need It</WhyYouNeedIt>
                <Description>
                  {item.description}
                  <SlideItemPrice>${item.price}</SlideItemPrice>
                </Description>
                <SlideDetailsAnim style={detailsAnim}>
                  <SlideViewDetails
                    left={item.detailOffsetX}
                    top={item.detailOffsetY}
                    onMouseUp={() => toggleOverlay()}
                  ></SlideViewDetails>
                </SlideDetailsAnim>
              </Item>
            </div>
          ))}
        </SliderElement>
        {showRightArrow && (
          <SlideArrow type="next" onMouseUp={() => nextSlide()} />
        )}
      </div>
    </div>
  );
};
