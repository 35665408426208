// @ts-nocheck
import { AccessoryKeys } from '../constants';
import type { ProductCatalogType } from '../models/main';
import type { ProductData } from '../types.generated';
import { getAttributeValueByName } from '../utils/getAttributeValueByName';
import { mapProductAttributesToConfigs } from './mapProductAttributesToConfigs';
import { mapProductAttributesToOptions } from './mapProductAttributesToOptions';

export const mapProductToAccessory = (
  product: ProductCatalogType,
  locale: string,
  context: Record<string, boolean> = {},
): any => {
  const { masterData, key, productType } = product;
  const accessoryBase = masterData.current as ProductData;
  const {
    name,
    description,
    masterVariant,
    variants = [],
    categories = [],
  } = accessoryBase;
  const productImages = masterVariant.images || [];
  const { centAmount, currencyCode } = masterVariant.price?.value || {};
  const sku = masterVariant.sku ?? '';

  const accessoryImages = productImages.map(productImage => ({
    src: productImage.url,
    alt: String(productImage.label),
  }));

  const configurations = mapProductAttributesToConfigs(
    masterVariant,
    variants,
    locale,
  );

  const { amount, currency } = configurations[0]?.price || {};

  const accessoryPrice: any['price'] = {
    amount: amount ?? centAmount,
    currency: currency ?? currencyCode,
  };

  const sortedVariants = variants;
  const attributes = mapProductAttributesToOptions(sortedVariants, locale);
  const legacyProductId: string =
    getAttributeValueByName(
      masterVariant.attributesRaw,
      AccessoryKeys.LEGACY_PRODUCT_ID,
    ) ?? '';

  const sortedByPriceVariants = variants;
  const discountPriceBase = sortedByPriceVariants[0]?.price?.discounted?.value;
  const accessoryDiscountedPrice = discountPriceBase && {
    amount: discountPriceBase.centAmount,
    currency: currency ?? currencyCode,
  };

  const displayNameForAllLocales =
    getAttributeValueByName(masterVariant.attributesRaw, AccessoryKeys.DISPLAY_NAME) ??
    {};

  const displayName =
    displayNameForAllLocales[locale] ||
    displayNameForAllLocales['en-US'];

  const sizeLabelForAllLocales =
    getAttributeValueByName(masterVariant.attributesRaw, AccessoryKeys.SIZE_LABEL) ?? {};

  const sizeLabel =
    sizeLabelForAllLocales[locale];

  return {
    id: legacyProductId,
    name: String(name),
    displayName,
    sizeLabel,
    slug: String(key),
    description: (description as unknown) as string,
    images: accessoryImages,
    attributes,
    price: accessoryPrice,
    discountPrice: accessoryDiscountedPrice,
    productType: String(productType?.key),
    variants: configurations,
    categories: categories.map(c => c.id),
    sku,
  };
};
