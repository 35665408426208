// import { Locale } from '@peloton/internationalize';
// import type { AccessoryBundleCommercetools } from '@ecomm/pg-shop-accessories-display/models';
// import { sortProducts } from '@ecomm/shop/sortProducts';
import { AccessoryBundleKeys } from '../constants';
import type { ProductCatalogType } from '../models/main';
// import {
//   getBundleAvailabilityState,
//   getCurrentAvailabilityState,
// } from '../product-states/getAvailabilityState';
import type { ProductData, ProductVariant } from '../types.generated';
import { calculateBundleDiscount } from '../utils/calculateBundleDiscount';
import { getAttributeRefResourceByName } from '../utils/getAttributeRefResourceByName';
import { getAttributeRefResourceSetByName } from '../utils/getAttributeRefResourceSetByName';
import { getAttributeValueByName } from '../utils/getAttributeValueByName';
import { getProductIdsFromBundle } from '../utils/getProductIdsFromBundle';
import { mapProductToAccessory } from './mapProductToAccessory';

export const mapCTBundleToAccessoryBundle = (
  ctBundle: ProductCatalogType,
  locale: string,
  context: Record<string, boolean> = {},
): any => {
  const { id, masterData, key, productType } = ctBundle;

  const bundleBase = masterData.current as ProductData;
  const { name, description, masterVariant, categories } = bundleBase;

  const bundleMasterVariant = masterVariant as ProductVariant & {
    attributes: { name: string; value: any }[];
  };

  const { centAmount: bundlePrice, currencyCode } =
    bundleMasterVariant.price?.value || {};

  const discountPriceBase = bundleMasterVariant.price?.discounted?.value;

  const bundleImages = bundleMasterVariant.images.map(image => ({
    src: image.url,
    alt: String(image.label),
  }));

  // const availabilityState = context[true]
  //   ? getBundleAvailabilityState(bundleBase, locale)
  //   : getCurrentAvailabilityState(bundleBase, locale);

  const configurable = getAttributeValueByName(
    masterVariant.attributesRaw,
    AccessoryBundleKeys.IS_CONFIGURABLE,
  );

  const legacyBundleId: string =
    getAttributeValueByName<AccessoryBundleKeys>(
      masterVariant.attributesRaw,
      AccessoryBundleKeys.LEGACY_ID,
    ) ?? '';

  const displayNameForAllLocales =
    getAttributeValueByName<AccessoryBundleKeys>(
      masterVariant.attributesRaw,
      AccessoryBundleKeys.DISPLAY_NAME,
    ) ?? {};

  const subscription = getAttributeRefResourceByName<AccessoryBundleKeys>(
    masterVariant.attributesRaw,
    AccessoryBundleKeys.SUBSCRIPTION,
  ) as ProductCatalogType;

  const bundleProducts = getAttributeRefResourceSetByName<AccessoryBundleKeys>(
    masterVariant.attributesRaw,
    AccessoryBundleKeys.PRODUCT_REFERENCES,
  ) as ProductCatalogType[];

  const bundleProductsIds = getProductIdsFromBundle(ctBundle);

  const bundleAccessories: any['products'] = bundleProductsIds.map(
    bundleProductId => {
      const bundleCtProduct = bundleProducts?.find(
        bundleProduct => bundleProduct.id === bundleProductId,
      );

      const {
        id: productId,
        description: productDescription,
        productType: bundleProductType,
        availability,
        name: productName,
        price,
        images,
        slug,
        attributes,
        variants,
        categories: accessoryCategories,
        displayName,
        sku,
      } = mapProductToAccessory(bundleCtProduct!, locale, context);

      return {
        id: productId,
        description: String(productDescription),
        productType: bundleProductType,
        availability,
        name: productName,
        price,
        images,
        slug,
        attributes,
        variants,
        categories: accessoryCategories,
        displayName,
        sku,
      };
    },
  );

  const upsellProducts = getAttributeRefResourceSetByName<AccessoryBundleKeys>(
    masterVariant.attributesRaw,
    AccessoryBundleKeys.UPSELLS,
  );
  const upsells = (upsellProducts || []).map(upsellProduct =>
    mapProductToAccessory(upsellProduct as ProductCatalogType, locale, context),
  );

  const discount = calculateBundleDiscount(bundlePrice, bundleAccessories);

  const displayName =
    displayNameForAllLocales[locale] ||
    displayNameForAllLocales['en-US'];

  const bundleDiscountedPrice = discountPriceBase && {
    amount: discountPriceBase.centAmount,
    currency: discountPriceBase.currencyCode,
  };

  const sku = masterVariant?.sku ?? '';

  return {
    id,
    name: String(name),
    displayName,
    slug: String(key),
    legacyBundleId: String(legacyBundleId),
    description: String(description),
    images: bundleImages,
    discountPrice: bundleDiscountedPrice,
    price: {
      amount: bundlePrice,
      currency: currencyCode,
    },
    // availability: availabilityState,
    products: bundleAccessories,
    discount: {
      amount: discount,
      currency: currencyCode,
    },
    upsells,
    subscription,
    image: bundleImages[0],
    configurable,
    categories: categories.map(c => c.id),
    productType: String(productType?.key),
    sku,
  };
};
