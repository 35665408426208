// @ts-nocheck
import type { AccessoryBundleKeys, AccessoryKeys, SparePartKeys } from '../constants';
import type { RawProductAttribute, RawProductSearchAttribute } from '../types.generated';

export const getAttributeValueByName = <
  T extends AccessoryKeys | AccessoryBundleKeys | SparePartKeys
>(
  attributes: Array<RawProductAttribute | RawProductSearchAttribute>,
  accessoryKey: T,
) => attributes.find(attribute => attribute.name === accessoryKey)?.value;
