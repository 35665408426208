import React, { useEffect, useState } from "react";
import "../App.css";
import { RouteComponentProps } from "@reach/router";
import { PackageSlides } from "components/PackageSlides";
import { MainCanvas } from "components/UI/MainCanvas";
import styled from "styled-components";
import { tsWidth, tsHeight, SliderChicTit } from "@ecomm/typography";
import { Images } from "constants/Images";
import { Navigation } from "components/Navigation";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import {
  ICsTouchscreenPackageFields,
  ICsTouchscreenProductFields,
} from "types/contentful";
import { Api } from "api/Api";
import { Entry } from "contentful";
import { useQuery } from "graphql-hooks";
import { AccessoriesItem } from "types/accessories";
import { PackageSlidesData } from "types/package";
// import { SlideViewDetails } from "components/SlideViewDetails";
import useProductsByKeys from "commercetools/hooks/useProductsByKeys";
import { IS_TEST_ENV } from "commercetools/client";

const SlideGlow = styled.div`
  position: absolute;
  ${tsWidth};
  ${tsHeight};
  background-color: #060607;
  /* background-image: url(${Images.globalTopGlow}); */
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  top: 0px;
  left: 0px;
`;

const SlideChicTitle = styled.div`
  position: absolute;
  ${tsWidth};
  ${SliderChicTit};
  color: #878b93;
  bottom: 310px;
  left: 0px;
  text-align: center;
  ${ccIpadPortrait`
    font-size: 16px;
    bottom: 150px;
  `}
`;

const PackageTitle = styled.div`
  position: absolute;
  ${tsWidth};
  ${SliderChicTit};
  color: #878b93;
  top: 100px;
  color: white;
  font-size: 28px;
  left: 0px;
  text-align: center;
`;

const getPackageBySlugQuery = (
  slug: string,
  catalogSlug: string,
  catalogLocale: string,
  catalogCurrency: string
) => {
  const PACKAGE_BY_SLUG_QUERY = `{
    catalog(slug: "${catalogSlug}", locale: ${catalogLocale}, currency: ${catalogCurrency}) {
      packageBySlug(slug: "${slug}") {
        id
        name
        slug
        description
        equipmentType
        connectedFitnessUnit{
          name
        }
        warranty {
          name
          description
        }
        price {
          amount
          currency
        }
        accessories {
          id
          name
          slug
          price {
            amount
            currency
          }
        }
      }
    }
  }`;
  return PACKAGE_BY_SLUG_QUERY;
};

export const PackageHome = (props: RouteComponentProps) => {
  const locationArray = props?.location?.pathname?.split("/") || [];

  const intlLocation = locationArray[1];
  let catalogSlug = "dotcom";
  let catalogLocale = "EN_US";
  let catalogCurrency = "USD";
  let apiLocale = "en-US";
  if (intlLocation === "ca") {
    catalogSlug = "ca_webstore";
    catalogLocale = "EN_CA";
    catalogCurrency = "CAD";
    apiLocale = "en-CA";
  }
  const curSection = locationArray[2];
  const slug = locationArray[5];
  const [packageData, setPackageData] = useState<Entry<
    ICsTouchscreenPackageFields
  > | null>(null);
  const [products, setProducts] = useState<
    Entry<ICsTouchscreenProductFields>[] | null
  >(null);
  const [graphData, setGraphData] = useState<any | null>(null);
  const [slideData, setSlideData] = useState<PackageSlidesData | null>(null);
  const [showTitle, setShowTitle] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      Api.getPackageBySlug(slug, apiLocale).then((response) =>
        setPackageData(response.items[0])
      );
    };
    const fetchProductData = async () => {
      Api.getProducts(apiLocale).then((response) => {
        const products: Entry<ICsTouchscreenProductFields>[] = response.items;
        const screenTypeProducts: Entry<
          ICsTouchscreenProductFields
        >[] = products.reduce(
          (acc: any, obj: Entry<ICsTouchscreenProductFields>) => {
            obj.fields.screenType &&
              // eslint-disable-next-line array-callback-return
              obj.fields.screenType.find((o) => {
                if (o.fields.path === curSection) {
                  acc.push(obj);
                }
              });
            return acc;
          },
          []
        );
        setProducts(screenTypeProducts);
      });
    };
    fetchData();
    fetchProductData();
  }, [apiLocale, curSection, slug]);

  const ctResponse = useProductsByKeys([slug]);
  const queryStr: string = getPackageBySlugQuery(
    slug,
    catalogSlug,
    catalogLocale,
    catalogCurrency
  );
  const { error, data } = useQuery(queryStr, {
    fetchOptionsOverrides: {
      mode: "cors",
    },
  });
  
  /// if (loading) console.log('loading');
  if (error) console.log("error", error);
  if (IS_TEST_ENV && !graphData && ctResponse.products) {
    setGraphData(ctResponse.products[0]);
  }
  if (data && !graphData && !IS_TEST_ENV) {
    setGraphData(data);
  }

  useEffect(() => {
    const combineData = () => {
      const graphDataProducts = graphData.catalog.packageBySlug.accessories;
      const getProductData = (slug: string) => {
        return products?.find((product) => product.fields.slug === slug);
      };
      const data = {
        slideMainUrl: packageData?.fields.mainSlideUrl.fields.file.url,
        title: packageData?.fields.name,
        description: packageData?.fields.description,
        iconUrl: packageData?.fields.iconUrl?.fields.file.url,
        iconUrlActive: packageData?.fields.iconUrlActive?.fields.file.url,
        price: graphData.catalog.packageBySlug.price.amount,
        listOnlyItems: packageData?.fields.listOnlyItems,
        slug: packageData?.fields.slug,
        items: graphDataProducts.reduce((a: AccessoriesItem[], p: any) => {
          // console.log(p);
          const productData = getProductData(p.slug);
          // console.log(productData)
          if (productData) {
            const item: AccessoriesItem = {
              slug: productData.fields.slug,
              title: productData.fields.name,
              description: productData.fields.description,
              slideUrl: productData.fields.slideUrl?.fields.file.url,
              imageUrl: productData.fields.imageUrl?.fields.file.url,
              iconUrl: productData.fields.iconUrl?.fields.file.url,
              iconUrlActive: productData.fields.iconUrlActive?.fields.file.url,
              price: p.price.amount,
              detailOffsetX: productData.fields.detailOffsetX,
              detailOffsetY: productData.fields.detailOffsetY,
              id: productData.fields.id,
              details: productData.fields.details
                ? productData.fields.details
                : [],
              multipleStr: productData.fields.multipleStr,
              alacarteUrl:
                productData.fields.alacarteUrl?.fields.file.url || "",
            };
            a.push(item);
          }
          return a;
        }, []),
      };

      // GraphQL has duplicate products
      function getUniqueListBy(arr: AccessoriesItem[], key: string) {
        //@ts-ignore
        const mp = new Map(arr.map((o) => [o.slug, { ...o, count: 0 }]));
        //@ts-ignore
        for (const { slug } of arr) mp.get(slug).count++;
        const result = Array.from(mp.values());
        return result;
        //@ts-ignore
        // return [...new Map(arr.map(item => [item[key], item])).values()]
      }

      const items = getUniqueListBy(data.items, "slug");
      data.items = items.sort((a, b) => a.id - b.id);
      setSlideData(data);
    };
    if (graphData && products && packageData) combineData();
  }, [graphData, packageData, products]);

  return (
    <MainCanvas>
      <SlideGlow></SlideGlow>
      {showTitle && <PackageTitle>{packageData?.fields.name}</PackageTitle>}
      {slideData && (
        <PackageSlides
          data={slideData}
          showTitle={setShowTitle}
          locationArray={locationArray}
        ></PackageSlides>
      )}
      <SlideChicTitle>In this package</SlideChicTitle>
      <Navigation></Navigation>
    </MainCanvas>
  );
};
