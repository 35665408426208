import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "@reach/router";
import styled from "styled-components";
import { Images } from "../constants/Images";
import {
  packageTitle1,
  packagePrice1,
  tsMainBlocks,
  tsLogo,
  tsWidth,
  tsHeight,
  AccessoriesText,
  touchPress1,
} from "@ecomm/typography";
import { tsbgblack1, white, tstxtgray1, mediumGray } from "@ecomm/colors/base";
import { HasImage } from "types/common";
import { LoadSpinner } from "./UI/LoadSpinner";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import {
  ICsTouchscreenPackageFields,
  ICsTouchscreenProductFields,
} from "types/contentful";
import { Api } from "api/Api";
import { Entry, EntryCollection } from "contentful";
import { useManualQuery } from "graphql-hooks";
import { AccessoriesItem } from "types/accessories";
import useProductsByKeys from "commercetools/hooks/useProductsByKeys";
import { CT_SLUGS_MAP } from "commercetools/constants";
import { IS_TEST_ENV } from "commercetools/client";

const MainPackages = styled.div`
  ${tsWidth};
  ${tsHeight};
  height: 1920px;
  height: auto;
  padding-bottom: 130px;
  background-color: ${tsbgblack1};
  position: relative;
  color: ${white};
  display: block;
  /* background-image: url(${Images.globalTopGlow}); */
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  ${ccIpadPortrait`
    height: auto;
    padding-bottom: 100px;
  `}
`;

const Logo = styled.div`
  /* background-image: url(${Images.globalGlowLogo}); */
  ${tsLogo};
`;

const ProductName = styled.div`
  color: #ff3347;
  text-transform: uppercase;
  font-size: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  /* padding: 80px 0 0 93px; */
  position: absolute;
  left: 93px;
  top: 80px;
`;

const PackageList = styled.div`
  display: block;
  padding-top: 190px;
  ${ccIpadPortrait`
    padding-top: 110px;
  `}
`;

const PackgeDetails = styled.a<HasImage>`
  ${tsMainBlocks};
  display: block;
  background-image: url(${({ image }) => (image ? image : null)});
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 0 38px 90px;
  ${ccIpadPortrait`
    margin: 0 auto 20px;
  `}
`;

const AccessoriesGrid = styled.div`
  ${tsWidth};
  padding-top: 200px;
  padding-left: 75px;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 450px);
  grid-column-gap: 24px;
  grid-row-gap: 2px;
  ${ccIpadPortrait`
    padding-top: 110px;
    grid-template-columns: repeat(2, 320px);
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    padding-left: 0px;
    margin: 0 auto;
    justify-content: center;
  `}
`;

const AccessoriesItemCont = styled.a<HasImage>`
  width: 450px;
  height: 640px;
  background-image: url(${({ image }) => (image ? image : null)});
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;
  ${touchPress1};
  ${ccIpadPortrait`
    width: 320px;
    height: 450px;
  `}
`;

interface AccessoriesBtnProps {
  onMouseUp: () => void;
  image: string;
  key: number;
  children: any;
}

export const AccessoriesBtn = (props: AccessoriesBtnProps) => {
  const { image, ...rest } = props;
  return <AccessoriesItemCont image={image} {...rest}></AccessoriesItemCont>;
};

const AccessoriesItemTitle = styled.div`
  position: absolute;
  bottom: 136px;
  font-weight: 600 !important;
  left: 60px;
  right: 74px;
  text-align: center;
  ${AccessoriesText};
  color: ${white};
  ${ccIpadPortrait`
    bottom: 100px;
    font-size: 20px;
  `}
`;

const AccessoriesItemPrice = styled.div`
  position: absolute;
  bottom: 84px;
  left: 60px;
  right: 74px;
  text-align: center;
  ${AccessoriesText};
  color: ${mediumGray};
  ${ccIpadPortrait`
    bottom: 60px;
    font-size: 18px;
  `}
`;

const AccessoriesShade = styled.div`
  position: fixed;
  ${tsWidth};
  height: 215px;
  bottom: 120px;
  background-image: url(${Images.accessShade});
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  ${ccIpadPortrait`
    bottom: 60px;
  `}
`;

const PackageContent = styled.div`
  ${tsMainBlocks};
  padding-right: 50%;
  text-align: left;
  display: block;
  position: relative;
`;

const PackageTitle = styled.div`
  padding-top: 140px;
  ${packageTitle1};
  margin-bottom: 30px;
  color: white;
  padding: 90px 50px 0 80px;
  /* border: 1px dotted red; */
  font-size: 45px;
  font-weight: 600;
  line-height: 1em;
  ${ccIpadPortrait`
    padding: 100px 10px 0;
    margin-bottom: 8px;
  `}
`;

const PackagePrice = styled.div`
  ${packagePrice1};
  color: ${tstxtgray1};
  padding-left: 80px;
  font-size: 28px;
  /* border: 1px dotted green; */
`;

const PackageAPR = styled.div`
  position: absolute;
  width: 450px;
  text-align: left;
  bottom: 70px;
  left: 0px;
  padding: 0 0 0 80px;
  font-size: 22px;
  color: ${tstxtgray1};
  /* border: 1px dotted white; */
  ${ccIpadPortrait`
    width: auto;
    font-size: 12px;
    bottom: 30px;
    right: auto;
  `}
`;

const AccessoriesCanvas = styled.div`
  ${tsWidth};
  padding-bottom: 225px;
  background-color: ${tsbgblack1};
  position: relative;
  color: ${white};
  display: block;
  /* background-image: url(${Images.globalTopGlow}); */
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
`;

const Logo2 = styled.div`
  /* background-image: url(${Images.globalGlowLogo}); */
  ${tsLogo};
  /* position: fixed; */
  position: absolute;
`;

export const ListView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [packages, setPackages] = useState<EntryCollection<
    ICsTouchscreenPackageFields
  > | null>(null);
  const [products, setProducts] = useState<
    Entry<ICsTouchscreenProductFields>[] | null
  >(null);
  const [preloadComplete, setPreloadComplete] = useState<boolean>(false);
  const [imgArr, setImgArr] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [accessoriesGraphData, setAccessoriesGraphData] = useState<any | null>(
    null
  );
  const [packageGraphData, setPackageGraphData] = useState<any | null>(null);
  const [accessoriesData, setAccessoriesData] = useState<
    AccessoriesItem[] | null
  >(null);

  const clearPackageData = () => {
    setPackages(null);
    setPackageGraphData(null);
    setImgArr([]);
    setIsLoading(true);
    setPreloadComplete(false);
  };
  const locationArray = location.pathname.split("/");

  const intlLocation: string = locationArray[1];
  let catalogSlug = "dotcom";
  let catalogLocale = "EN_US";
  let catalogCurrency = "USD";
  let apiLocale = "en-US";
  let CT_SLUGS = CT_SLUGS_MAP[intlLocation].bike;
  if (intlLocation === "ca") {
    catalogSlug = "ca_webstore";
    catalogLocale = "EN_CA";
    catalogCurrency = "CAD";
    apiLocale = "en-CA";
  }
  //slug: "ca_webstore", locale: EN_CA, currency: CAD
  const curSection = locationArray[2];
  const curCategory = locationArray[4];
  let product = "BIKE";
  switch (curCategory) {
    case "tread":
      product = "TREAD";
      CT_SLUGS = CT_SLUGS_MAP[intlLocation].tread;
      break;
    case "treadplus":
      product = "TREAD+";
      break;
    case "bikeplus":
      product = "BIKE+";
      CT_SLUGS = CT_SLUGS_MAP[intlLocation].bikePlus;
      break;
    case "row":
      product = "ROW";
      break;
    default:
      break;
  }

  const PACKAGES_BY_TYPE_QUERY = `{
    catalog(slug: "${catalogSlug}", locale: ${catalogLocale}, currency: ${catalogCurrency}) {
      packages(equipmentType: ${curCategory.toUpperCase()}) {
        id
        name
        slug
        description
        price {
          amount
        }
      }
    }
  }`;

  const ACCESSORIES_BY_TYPE_QUERY = `{
    catalog(slug: "${catalogSlug}", locale: ${catalogLocale}, currency: ${catalogCurrency}) {
      accessories(equipmentType: ${curSection.toUpperCase()}) {
        description
        slug
        price {
          amount
          currency
        }
        variants {
          price {
            amount
          }
        }
      }
    }
  }`;

  const ACCESSORIES_BY_SLUG_QUERY = `query getProductBySlug($productSlug:Slug!) {
    catalog(slug: "${catalogSlug}", locale: ${catalogLocale}, currency: ${catalogCurrency}) {
      productBySlug(slug: $productSlug) {
        description
        slug
        id
        price {
          amount
          currency
        }
        variants {
          price {
            amount
          }
        }
      }
    }
  }`;

  const [fetchPackages] = useManualQuery(PACKAGES_BY_TYPE_QUERY, {
    variables: {
      currency: "USD",
      locale: "EN_US",
      catalog: "dotcom",
      productLine: "ROW",
    },
  });
  // const [fetchRowPackages] = useManualQuery(PACKAGES_BY_PRODUCTLINE);
  const [fetchAccessoriesByType] = useManualQuery(ACCESSORIES_BY_TYPE_QUERY);
  const [fetchAccessoriesBySlug] = useManualQuery(ACCESSORIES_BY_SLUG_QUERY);

  useEffect(() => {
    clearPackageData();
    const fetchPackageData = async () => {
      Api.getPackagesByEquipmentType(curCategory.toUpperCase(), apiLocale).then(
        (response) => {
          const imgs: string[] = [];
          response.items.map((p) =>
            imgs.push(p.fields.packageNavUrl?.fields.file.url as string)
          );
          // console.log("packages response", response);
          setPackages(response);
          setImgArr(imgs);
        }
      );
    };
    const fetchProductData = async () => {
      Api.getProducts(apiLocale).then((response) => {
        const products: Entry<ICsTouchscreenProductFields>[] = response.items;
        const screenTypeProducts: Entry<
          ICsTouchscreenProductFields
        >[] = products.reduce(
          (acc: any, obj: Entry<ICsTouchscreenProductFields>) => {
            obj.fields.screenType &&
              // eslint-disable-next-line array-callback-return
              obj.fields.screenType.find((o) => {
                if (o.fields.path === curSection) {
                  acc.push(obj);
                }
              });
            return acc;
          },
          []
        );
        setProducts(screenTypeProducts);
      });
    };
    if (curCategory !== "alacarte") {
      fetchPackageData();
    } else {
      fetchProductData();
    }
  }, [apiLocale, curCategory, curSection]);
  const productData = useProductsByKeys(CT_SLUGS, intlLocation);

  useEffect(() => {
    // CT call on test/stage
    if (curCategory !== 'alacarte' && productData.products && !packageGraphData && IS_TEST_ENV) {
        setPackageGraphData(productData.products);
    }
  }, [productData, packageGraphData, curCategory])
  useEffect(() => {
    const getPackageGraphData = async () => {
      const packageGraphData = await fetchPackages();
      setPackageGraphData(packageGraphData.data.catalog.packages);
    };
    // Kronos call on Prod
    if (curCategory !== "alacarte" && !IS_TEST_ENV) {
      getPackageGraphData();
    }
  }, [curCategory, fetchPackages]);

  const slugs = products?.map((product) => product.fields.slug) || [];
  const ctAccessories = useProductsByKeys(slugs, intlLocation);

  useEffect(() => {
    if (!accessoriesGraphData && ctAccessories?.products?.length > 0 && IS_TEST_ENV) {
      setAccessoriesGraphData(ctAccessories.products);
    }
  }, [ctAccessories]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        curSection !== "yoga" &&
        curSection !== "strength" &&
        curSection !== "row"
      ) {
        const adata = await fetchAccessoriesByType();
        setAccessoriesGraphData(adata.data?.catalog.accessories);
      } else {
        if (products) {
          Promise.all(
            products.map(async (product) => {
              const p = await fetchAccessoriesBySlug({
                variables: { productSlug: product.fields.slug },
              });
              return p;
            })
          ).then((response) => {
            const adata = response.map(
              (r) => !r.error && r.data.catalog.productBySlug
            );
            setAccessoriesGraphData(adata);
          });
        }
      }
    };
    if (products && !accessoriesGraphData && !IS_TEST_ENV) fetchData();
  }, [
    products,
    accessoriesGraphData,
    curSection,
    fetchAccessoriesByType,
    fetchAccessoriesBySlug,
  ]);

  useEffect(() => {
    const combineData = () => {
      const getPrice = (variants: any[], slug?: string) => {
        if (IS_TEST_ENV && slug) {
          const acc = accessoriesGraphData.find((a: any) => a.slug === slug);
          return `${acc?.price?.amount / 100}`;
        }
        if (
          variants.length > 1 &&
          variants[0].price.amount !== variants[variants.length - 1].price.amount
        ) {
          return `${variants[0].price.amount / 100} - $${
            variants[variants.length - 1].price.amount / 100
          }`;
        } else {
          return `${variants[0].price.amount / 100}`;
        }
      };
      const getProductData = (slug: string) => {
        const ctSlugMap = {
          'peloton-x-camelbak': 'podium-sport-bottle',
          'yoga-blocks': 'yoga-blocks-v2',
          'yoga-strap': 'yoga-strap-v2',
          'yoga-block': 'no-match', // this item is not for sale
        };
        // mapping the slugs due to kronos and ct differences
        // @ts-expect-error
        const matchSlug = ctSlugMap[slug] || slug;
        return products?.find((product) => {
         return product.fields.slug === matchSlug;
        } );
      };

      const accessories = accessoriesGraphData.reduce(
        (a: AccessoriesItem[], p: any) => {
          const productData = getProductData(p.slug);
          if (productData) {
            const item: AccessoriesItem = {
              slug: productData.fields.slug,
              title: productData.fields.name,
              description: productData.fields.description,
              slideUrl: productData.fields.slideUrl?.fields.file.url,
              imageUrl: productData.fields.imageUrl?.fields.file.url,
              iconUrl: productData.fields.iconUrl?.fields.file.url,
              iconUrlActive: productData.fields.iconUrlActive?.fields.file.url,
              price: getPrice(p.variants, p.slug), //p.price.amount/100,
              detailOffsetX: productData.fields.detailOffsetX,
              detailOffsetY: productData.fields.detailOffsetY,
              id: productData.fields.id,
              details: productData.fields.details
                ? productData.fields.details
                : [],
              multipleStr: productData.fields.multipleStr,
              alacarteUrl: productData.fields.alacarteUrl?.fields.file.url,
            };
            a.push(item);
          }

          return a;
        },
        []
      );
      accessories.sort((a: AccessoriesItem, b: AccessoriesItem) => a.id - b.id);
      setAccessoriesData(accessories);
    };
    if (accessoriesGraphData && products) combineData();
  }, [accessoriesGraphData, products]);

  useEffect(() => {
    if (packages && !preloadComplete && imgArr.length > 0) {
      const promises: Promise<string>[] = [];
      const cacheImages = (arr: string[]) => {
        arr.forEach((src) => {
          const p = new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(src);
            img.onerror = () => reject();
          });
          promises.push(p);
        });
        Promise.all(promises).then((results) => {
          setIsLoading(false);
          setPreloadComplete(true);
        });
      };
      cacheImages(imgArr);
    }
  }, [packages, preloadComplete, imgArr]);

  const getPackagePrice = (slug: string) => {
    //@ts-ignore
    const p = packageGraphData.find((a) => a.slug === slug);
    return p ? p.price.amount / 100 : 0;
  };

  const getVariablePrice = (slug: string) => {
    if (slug.includes("tread") || slug.includes("ultimate"))
      return (
        <>
          <span></span>
        </>
      );
  };

  const getPackageContent = (slug: string, name: string) => {
    if (slug.includes("opc")) {
      const rentalPrice =
        product === "BIKE"
          ? intlLocation === "ca"
            ? "129"
            : "89"
          : intlLocation === "ca"
          ? "169"
          : "119";
      return (
        <PackageContent>
          <PackageTitle>{name}</PackageTitle>
          <PackagePrice>${rentalPrice} per month</PackagePrice>
          {/* <PackageAPR>
            As low as $
            {packageGraphData && getFinancingPrice(slug).toLocaleString()}
            /mo with 0% APR
          </PackageAPR> */}
        </PackageContent>
      );
    } else {
      return (
        <PackageContent>
          <PackageTitle>{name}</PackageTitle>
          <PackagePrice>
            {packageGraphData && getVariablePrice(slug)}$
            {packageGraphData && getPackagePrice(slug).toLocaleString()} with{" "}
            {product}
          </PackagePrice>
          <PackageAPR>
            As low as $
            {packageGraphData && getFinancingPrice(slug).toLocaleString()}
            /mo with 0% APR
          </PackageAPR>
        </PackageContent>
      );
    }
  };

  const getFinancingPrice = (slug: string) => {
    //@ts-ignore
    const p = packageGraphData.find((a) => a.slug === slug);
    return p ? Math.ceil(p.price.amount / 100 / 39) : 0;
  };

  return (
    <div>
      {curCategory !== "alacarte" ? (
        <MainPackages>
          <Logo></Logo>
          <ProductName>Peloton &nbsp; {product}</ProductName>
          {isLoading ? (
            <LoadSpinner>Loading...</LoadSpinner>
          ) : (
            <PackageList>
              {packageGraphData &&
                packages &&
                packages.items.map((p, i) => {
                  const price = getPackagePrice(p.fields.slug);
                  return price ? (
                    <PackgeDetails
                      onMouseUp={() =>
                        navigate(
                          `/${intlLocation}/${curSection}/package/${curCategory}/${p.fields.slug}`,
                          { replace: false }
                        )
                      }
                      key={i}
                      image={imgArr[i]}
                    >
                      {getPackageContent(p.fields.slug, p.fields.name)}
                      {/* <PackageContent>
                        <PackageTitle>{p.fields.name}</PackageTitle>
                        <PackagePrice>
                          {packageGraphData && getVariablePrice(p.fields.slug)}$
                          {packageGraphData &&
                            getPackagePrice(
                              p.fields.slug
                            ).toLocaleString()}{" "}
                          with {product}
                        </PackagePrice>
                        <PackageAPR>
                          As low as $
                          {packageGraphData &&
                            getFinancingPrice(p.fields.slug).toLocaleString()}
                          /mo with 0% APR
                        </PackageAPR>
                      </PackageContent> */}
                    </PackgeDetails>
                  ) : null;
                })}
            </PackageList>
          )}
        </MainPackages>
      ) : (
        <AccessoriesCanvas>
          <Logo2 />
          {accessoriesData && (
            <AccessoriesGrid>
              {accessoriesData.map((item, i) => {
                return (
                  <AccessoriesBtn
                    key={i}
                    image={item.alacarteUrl}
                    onMouseUp={() =>
                      navigate(
                        `/${intlLocation}/${curSection}/accessories/${i}`,
                        {
                          replace: false,
                        }
                      )
                    }
                  >
                    <AccessoriesItemTitle>{item.title}</AccessoriesItemTitle>
                    <AccessoriesItemPrice>${item.price}</AccessoriesItemPrice>
                  </AccessoriesBtn>
                );
              })}
            </AccessoriesGrid>
          )}
          <AccessoriesShade />
        </AccessoriesCanvas>
      )}
    </div>
  );
};
